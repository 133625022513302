import React from "react";
import { Link, useLocation } from "react-router-dom";
import LinkButton from "../LinkButton";

export default function NavDesktop() {
  const location = useLocation();
  return (
    <nav className="hidden lg:grid max-w-full xl:max-w-7xl px-8 xl:px-0">
      <div className="h-16 flex items-center gap-4">
        <a className="p-0" href="https://cyberpeaceinstitute.org/">
          <img
            className="h-16 xl:-ml-2"
            src="/images/cyberpeace-institute-logo-white.svg"
            alt="Home"
          />
        </a>
        <span className="text-white xl:-ml-2">|</span>
        <Link
          className={`text-white ${location.pathname === "/" ? "active" : ""}`}
          to="/"
        >
          Home
        </Link>
        <Link
          className={`text-white ${
            location.pathname === "/about" ? "active" : ""
          }`}
          to="/about"
        >
          About&nbsp;CIT
        </Link>
        <Link
          className={`text-white ${
            location.pathname === "/methodology" ? "active" : ""
          }`}
          to="/methodology"
        >
          Methodology
        </Link>
        <Link
          className={`text-white ${
            location.pathname === "/glossary" ? "active" : ""
          }`}
          to="/glossary"
        >
          Glossary
        </Link>
      </div>
      <div className="flex items-center justify-end gap-4">
        <LinkButton
          className="w-44 py-1 px-3 bg-white text-center font-extrabold border-white text-cpi hover:bg-sec hover:border-sec"
          to="/explore"
        >
          Access Data
        </LinkButton>
        <LinkButton
          className="w-44 py-1 px-3 bg-cpi text-center text-white border border-white hover:bg-sec hover:border-sec"
          to="/act"
        >
          Act Now
        </LinkButton>
      </div>
    </nav>
  );
}
