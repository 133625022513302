import React from "react";
import { overrideClassNames } from "./utils";
import DownArrow from "./DownArrow";

interface Props {
  className?: string;
  showArrow?: boolean;
}

const defaultClassNames =
  "relative rounded-full h-96 w-96 p-16 flex flex-col items-center justify-center bg-white text-cpi";

export default function Bubble(props: Props) {
  return (
    <p
      className={`${overrideClassNames(
        defaultClassNames,
        props.className ?? ""
      )}`}
    >
      <strong className="pb-4">Are you</strong>
      <br />a policy maker, health sector or healthcare professional, industry
      representative, researcher, data scientist, developer...?
      {props.showArrow && (
        <>
          <DownArrow className="position-absolute -bottom-4 mt-16" />
          <DownArrow
            className="position-absolute -bottom-3 mt-16"
            color="#fff"
          />
        </>
      )}
    </p>
  );
}
