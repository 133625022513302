import React, { AnchorHTMLAttributes } from "react";

export default function A(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { children, ...p } = props;

  const extraProps = {
    ...p,
    ...(p.href?.startsWith("http") ? { target: "_blank" } : {}),
  };

  return (
    <a className="underline text-cpi" {...extraProps}>
      {children}
    </a>
  );
}
