import React, { PropsWithChildren, ReactNode } from "react";
import { Helmet } from "react-helmet";
import LinkButton from "./LinkButton";

interface Props {
  title: string;
  intro: ReactNode;
  hideBottomLinks?: boolean;
}

export default function Page(props: PropsWithChildren<Props>) {
  return (
    <>
      <Helmet>
        <title>{props.title} - Cyber Incident Tracer #HEALTH</title>
      </Helmet>
      <article className="max-w-screen-xl mx-auto py-16 px-4 md:px-0">
        <h1 className="mb-8 text-cpi font-extrabold text-5xl text-center">
          {props.title}
        </h1>

        <div className="max-w-3xl mx-auto text-cpi text-center font-light">
          {props.intro}
        </div>

        {props.children}

        {!props.hideBottomLinks && (
          <div className="my-16 flex justify-center gap-8 text-xl">
            <LinkButton
              className="w-64 px-4 md:px-16 py-3 md:py-4 text-cpi text-center"
              to="/explore"
            >
              Access Data
            </LinkButton>
            <LinkButton
              className="w-64 px-4 md:px-16 py-3 md:py-4 font-extrabold bg-cpi text-white text-center"
              to="/act"
            >
              Act Now
            </LinkButton>
          </div>
        )}
      </article>
    </>
  );
}
