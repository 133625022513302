import { ChangeEvent, InputHTMLAttributes, useEffect, useState } from "react";

// The `DebouncedInput` is from https://tanstack.com/table/v8/docs/examples/react/filters
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 250,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

interface GlobalFilterProps {
  globalFilter: string;
  setGlobalFilter: (s: string | undefined) => void;
}

export default function DataTableGlobalFilter(props: GlobalFilterProps) {
  const { globalFilter, setGlobalFilter } = props;

  const onChange = (value: string | number) => {
    setGlobalFilter(`${value}`);
  };

  return (
    <DebouncedInput
      className="form-input form-control"
      value={globalFilter || ""}
      onChange={onChange}
      placeholder={`Search in table`}
    />
  );
}
