import * as d3 from "d3";
import { ZoomTransform } from "d3-zoom";
import { useEffect, useState } from "react";

export interface Dimensions {
  width: number;
  height: number;
}

export function useZoomState(
  dimensions: Dimensions,
  initZoom?: ZoomTransform
): [ZoomTransform, VoidFunction, VoidFunction, Function] {
  let graphRef: any = null;

  const [zoomState, setZoomState] = useState<ZoomTransform>(
    d3.zoomIdentity.translate(0, 0).scale(1)
  );

  const getTransformer = (k: number, scale: [number, number]) => {
    const t0 = zoomState;
    const p0: [number, number] = [dimensions.width / 2, dimensions.height / 2];

    const p1 = t0.invert(p0);
    const k1 = zoomState.k * k;

    const scaleVal = Math.max(Math.min(k1, scale[1]), scale[0]);

    const translateX = p0[0] - p1[0] * scaleVal;
    const translateY = p0[1] - p1[1] * scaleVal;

    return d3.zoomIdentity.translate(translateX, translateY).scale(scaleVal);
  };

  const zoomIn = () => {
    const transformer = getTransformer(1.4, zoom.scaleExtent());
    zoom.transform(d3.select(graphRef), transformer);
    setZoomState(transformer);
  };

  const zoomOut = () => {
    const transformer = getTransformer(0.6, zoom.scaleExtent());
    zoom.transform(d3.select(graphRef), transformer);
    setZoomState(transformer);
  };

  const zoom: d3.ZoomBehavior<Element, any> = d3
    .zoom()
    .scaleExtent([0.5, 20]) // This control how much you can unzoom (x0.5) and zoom (x20)
    .extent([
      [0, 0],
      [dimensions.width, dimensions.height],
    ])
    .translateExtent([
      [-dimensions.width, -dimensions.height],
      [dimensions.width * 2, dimensions.height * 2],
    ]);

  const refCallback = (ref: SVGGElement | null) => {
    graphRef = ref;
  };

  useEffect(() => {
    // Set the zoom and Pan features: how much you can zoom, on which part, and what to do when there is a zoom
    zoom.on("zoom", updateChart);

    // A function that updates the chart when the user zoom and thus new boundaries are available
    function updateChart(event: any) {
      setZoomState(event.transform);
    }

    d3.select(graphRef).call(zoom).on("wheel.zoom", null);

    zoom.transform(
      d3.select(graphRef),
      initZoom
        ? initZoom
        : d3.zoomIdentity
            .translate(dimensions.width / 3.5, dimensions.height / 3.2)
            .scale(1.6)
    );

    return () => {
      zoom.on("zoom", null);
    };
  }, []);

  return [zoomState, zoomIn, zoomOut, refCallback];
}
