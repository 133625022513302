import React from "react";
import { overrideClassNames } from "./utils";

export enum DownArrowSize {
  Small = 1,
  Big = 2,
}

interface Props {
  color?: string;
  className?: string;
  size?: DownArrowSize;
}

const defaultClassNames = "block ml-auto mr-auto";

export default function DownArrow(props: Props) {
  const {
    className,
    color = "var(--color-primary)",
    size = DownArrowSize.Small,
  } = props;
  return (
    <span
      className={overrideClassNames(defaultClassNames, className)}
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
        borderTop: `${size}rem solid ${color}`,
        borderLeft: `${size / 2}rem solid transparent`,
        borderRight: `${size / 2}rem solid transparent`,
      }}
    />
  );
}
