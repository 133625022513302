import React, { PropsWithChildren } from "react";

interface Props {
  title: React.ReactNode;
}

export default function Section(props: PropsWithChildren<Props>) {
  return (
    <section className="border-cpi border-t my-16 py-8 md:grid grid-cols-3 gap-8">
      <h2 className="text-5xl mb-4 text-cpi w-full max-w-lg">{props.title}</h2>
      <div className="col-span-2">{props.children}</div>
    </section>
  );
}
