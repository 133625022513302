import React from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "../../icons/CyberPeace-Logo-White-notext.png";
import LogoGreen from "../../icons/CyberPeace-Logo-Green-No-Text.svg";
import LinkButton from "../LinkButton";

export default function NavMobile() {
  const location = useLocation();
  const [showMenu, setShowMenu] = React.useState(false);

  React.useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <nav className="lg:hidden relative">
      <button
        className="position-absolute h-16 right-4"
        onClick={() => setShowMenu(true)}
        title="Open menu"
      >
        <i className="fas fa-bars text-white text-3xl"></i>
      </button>
      {location.pathname !== "/" && (
        <div className="grid grid-cols-4">
          <a className="pl-2" href="https://cyberpeaceinstitute.org/">
            <img className="h-16 w-auto" src={LogoWhite} alt="Home" />
          </a>
          <Link
            className="col-span-2 flex items-center justify-center text-white"
            to="/"
          >
            Home
          </Link>
          <span></span>
        </div>
      )}
      {showMenu && (
        <div className="fixed w-screen inset-0 bg-white overflow-y-auto">
          <button
            className="position-absolute h-16 right-4"
            onClick={() => setShowMenu(false)}
            title="Close menu"
          >
            <i className="fas fa-times text-cpi text-3xl"></i>
          </button>
          <div className="grid grid-cols-4">
            <a className="pl-2" href="https://cyberpeaceinstitute.org/">
              <img className="h-16 w-auto" src={LogoGreen} alt="Home" />
            </a>
            <Link
              className="col-span-2 flex items-center justify-center text-cpi"
              to="/"
            >
              Home
            </Link>
            <span></span>
          </div>

          <div className="px-8">
            <LinkButton className="block my-4" to="/explore">
              Access Data
            </LinkButton>
            <LinkButton
              className="block mt-4 mb-16 bg-cpi text-white text-center font-extrabold"
              to="/act"
            >
              Act Now
            </LinkButton>

            <LinkButton className="block my-4" to="/about">
              About CIT
            </LinkButton>
            <LinkButton className="block my-4" to="/methodology">
              Methodology
            </LinkButton>
            <LinkButton className="block my-4" to="/glossary">
              Glossary
            </LinkButton>
          </div>
        </div>
      )}
    </nav>
  );
}
