export function getWidth(margin: Margin) {
  return getWidthWithMin(margin, 0);
}

export function getWidthWithMin(margin: Margin, min: number) {
  const width = window.innerWidth > min ? window.innerWidth : min;
  if (width >= 992) {
    return width * 0.66666667 - margin.left - margin.right - 40;
  } else {
    return width - margin.left - margin.right - 30;
  }
}

export function handleResize(
  dimensions: Dimensions,
  setDimensions: SetDimensions,
  margin: Margin
) {
  handleResizeWithMin(dimensions, setDimensions, margin, 0);
}

export function handleResizeWithMin(
  dimensions: Dimensions,
  setDimensions: SetDimensions,
  margin: Margin,
  min: number
) {
  const newSize = getWidthWithMin(margin, min);
  if (dimensions.width !== newSize) {
    setDimensions({
      ...dimensions,
      width: newSize,
    });
  }
}

export type SetDimensions = (a: Dimensions) => void;

export interface Dimensions {
  width: number;
  height: number;
}

export interface Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
