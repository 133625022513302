import React from "react";
import "./App.css";
import Navigation from "./lib/Navigation/Navigation";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Visualizations from "./diagrams/Visuals/Visualizations";
import AttackPage from "./routes/attack/AttackPage";
import Act from "./routes/act/Act";
import Glossary from "./routes/glossary/Glossary";
import Methodology from "./routes/methodology/Methodology";
import Home from "./routes/home/Home";

import Footer from "./lib/Footer";
import About from "./routes/about/About";
import ScrollToTop from "./lib/ScrollToTop";
import Analytics from "./lib/Analytics";
import NotFound from "./routes/not-found/NotFound";
import Maintenance from "./lib/Maintenance";
import OutOfDate from "./lib/OutOfDate";

// import Highcharts from 'highcharts';
// import DarkUnica from 'highcharts/themes/dark-unica';
// DarkUnica(Highcharts);

export default function App() {
  return (
    <BrowserRouter>
      <div className="app mt-16">
        <Navigation />
        <ScrollToTop />
        <Analytics />
        {(window as any).MAINTENANCE_MODE === "true" && <Maintenance />}
        {(window as any).OUT_OF_DATE === "true" && (
          <Switch>
            <Route path={`/`} exact component={undefined} />
            <Route path="*">
              <div className="bg-sec p-8">
                <OutOfDate />
              </div>
            </Route>
          </Switch>
        )}

        <main className="content">
          <Switch>
            <Route path={`/`} exact component={Home} />
            <Route path={`/explore`} component={Visualizations} />
            <Route path={`/attack/:id`} component={AttackPage} />
            <Route path={`/methodology`} component={Methodology} />
            <Route path={`/glossary`} component={Glossary} />
            <Route path={`/about`} component={About} />
            <Route path={`/act`} component={Act} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </main>

        <Footer />
      </div>
    </BrowserRouter>
  );
}
