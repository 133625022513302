import React from "react";

import LogoSpin from "../icons/CyberPeace-Logo-Green-No-Text.svg";

export default function Loading() {
  return (
    <div className="fixed top-16 bottom-0 flex flex-col justify-center items-center bg-gray-50 bg-opacity-80 w-screen z-50">
      <img
        className="w-64 h-64 animate-pulse"
        src={LogoSpin}
        alt=""
        style={{ transformOrigin: "50% 50%" }}
      />
      <p className="mt-4 text-cpi font-bold">Crunching data...</p>
    </div>
  );
}
