import React from "react";
import * as d3 from "d3";
import colorDictionary from "../../utils/colors/ColorDictionary";
import { Attack } from "../types";
import Moment from "moment";
import { getWidthWithMin } from "../../utils/window";
import { Selection } from "d3-selection";
import "./TimeBarChartWithDrillDownByFilter.css";
import {
  returnAttackCategories,
  returnAttackTypes,
  returnRegions,
  returnSubSectors,
} from "../../lib/filters/utils";
import { Tooltip } from "react-svg-tooltip";

let cachedCategoryColor: Record<string, string> = {};

interface Props {
  attacks: Attack[];
  filterBy: string;
  allowDrilldown: boolean;
}

function fillColor(color: string | undefined, category: string, hover: string) {
  if (!color) {
    return undefined;
  }
  if (hover && hover !== category) {
    return color + "40";
  }

  return color;
}

function TimeBarChartWithDrillDownByFilter(props: Props) {
  // set the dimensions and margins of the graph
  let margin = { top: 15, right: 10, bottom: 50, left: 80 };
  const width = getWidthWithMin(margin, 900);
  let height = 340 - margin.top - margin.bottom;
  let rectWidth = 22;

  if (width > 3000) {
    margin = { top: 15, right: 150, bottom: 50, left: 480 };
    height = 800 - margin.top - margin.bottom;
    rectWidth = 58;
  }

  const [dimensions, setDimensions] = React.useState({ width, height });

  const { attacks, filterBy, allowDrilldown } = props;
  const [drilldown, setDrilldown] = React.useState<string>("");

  const [hover, setHover] = React.useState<string>("");

  const svgRef = React.useRef(null);

  const series = getSeries(attacks, filterBy, drilldown);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        ...dimensions,
        width: getWidthWithMin(margin, 900),
      });
    }

    window.addEventListener("resize", handleResize);

    return function cleanupResizeListener() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const stackedData = d3
    .stack<Record<string, AttackPerMonth>, string>()
    .keys(series.types)
    .value((d, key, i, data) => {
      return d[key]?.y;
    });

  let domainStart = new Date("1/1/2020");
  let domainEnd = new Date("2/1/2022");

  if (series.data.length > 0) {
    domainStart = Object.values(series.data[0])[0].x;
    domainEnd = Object.values(series.data[series.data.length - 1])[0].x;
  }

  const x = d3
    .scaleTime<number, number>()
    .domain([domainStart, domainEnd])
    .range([0, dimensions.width - margin.left - margin.right]);

  const y = d3
    .scaleLinear()
    .domain([0, roundUp5(series.monthlyMax)])
    .range([dimensions.height, 0]);

  const xAxisRefCallback = (ref: any) => {
    let xAxis: Selection<SVGGElement, any, any, any> = d3.select<
      SVGGElement,
      any
    >(ref);
    xAxis.call(
      d3
        .axisBottom<Date>(x)
        .tickFormat(d3.timeFormat("%b '%y"))
        .ticks(series.data.length / 3)
    );
  };

  const yAxisRefCallback = (ref: any) => {
    const yAxis = d3.select<SVGGElement, any>(ref);
    const yAxisTicks =
      y.domain()[1] > 5
        ? Array.from(Array(y.domain()[1] / 5 + 1), (_, i) => i * 5)
        : y.ticks().filter((tick) => {
            return Number.isInteger(tick);
          });
    yAxis.call(
      d3
        .axisLeft(y)
        .tickValues(yAxisTicks)
        .tickSize(dimensions.width)
        .tickFormat(d3.format("d"))
    );
  };

  return (
    <div className="timebar-chart">
      <svg
        ref={svgRef}
        className="voronoi-treemap-svg"
        width={dimensions.width + margin.left + margin.right}
        height={dimensions.height + margin.top + margin.bottom}
      >
        <defs>
          <clipPath id="clipPathTimeChart">
            <rect
              width={dimensions.width}
              height={dimensions.height}
              x={0}
              y={0}
            ></rect>
          </clipPath>
        </defs>
        <text
          className="y label"
          textAnchor="middle"
          x={(margin.top + margin.bottom) / 2}
          y={dimensions.height / 2}
          transform="translate(5,0) rotate(270 26.078125 158)"
        >
          Number of Incidents
        </text>
        <text
          className="x label"
          textAnchor="middle"
          x={(dimensions.width + margin.left + margin.right) / 2}
          y={dimensions.height + margin.top + margin.bottom - 5}
        >
          Year - Month
        </text>
        <g
          ref={yAxisRefCallback}
          className="yAxis"
          transform={
            "translate(" +
            (dimensions.width + (margin.left + margin.right) / 2) +
            "," +
            margin.top +
            ")"
          }
        />
        <g
          ref={xAxisRefCallback}
          className="xAxis"
          transform={
            "translate(" +
            margin.left +
            "," +
            (dimensions.height + margin.top + 1) +
            ")"
          }
        />
        <g
          clipPath="url(#clipPathTimeChart)"
          transform={
            "translate(" +
            (margin.left - rectWidth / 2) +
            "," +
            margin.top +
            ")"
          }
        >
          {stackedData(series.data).map((category, i) => (
            <g
              key={`outerGroup${i}`}
              onClick={() =>
                !allowDrilldown || drilldown ? "" : setDrilldown(category.key)
              }
            >
              {category.map((rect, index) => (
                <g key={`innerGroup${i}${index}`}>
                  {!isNaN(y(rect[1])) && y(rect[1]) !== undefined && (
                    <>
                      <rect
                        onMouseOver={() => setHover(category.key)}
                        onMouseOut={() => setHover("")}
                        x={x(Object.values(rect.data)[0].x)}
                        y={y(rect[1])}
                        height={y(rect[0]) - y(rect[1])}
                        width={rectWidth}
                        fill={fillColor(
                          rect.data[category.key]?.color,
                          category.key,
                          hover
                        )}
                        stroke="#ffffff"
                        strokeWidth="1"
                        className={`${
                          !allowDrilldown || drilldown ? "child" : "parent"
                        }`}
                        ref={rect.data[category.key].ref}
                      />
                      <Tooltip triggerRef={rect.data[category.key].ref}>
                        <foreignObject
                          className="timebar-chart-tooltip-container"
                          textAnchor="start"
                          width={1}
                          height={1}
                        >
                          <div
                            className="timebar-chart-tooltip-container-inner"
                            style={{
                              borderColor: rect.data[category.key]?.color,
                            }}
                          >
                            {getCategoryModalDataForMonth(
                              rect.data[category.key]
                            )}
                          </div>
                        </foreignObject>
                      </Tooltip>
                    </>
                  )}
                </g>
              ))}
            </g>
          ))}
        </g>
        <g>
          <foreignObject
            className="drillup-container"
            textAnchor="end"
            fontSize="18px"
            fontWeight="bold"
            width={70}
            height={40}
            x={dimensions.width - margin.right - 30}
            y={-4}
          >
            {drilldown && (
              <div
                className="rounded-full btn bg-white hover:text-cpi text-cpi border border-cpi"
                onClick={() => setDrilldown("")}
              >
                Back
              </div>
            )}
          </foreignObject>
        </g>
      </svg>

      <div className="timebar-chart-categories">
        <div className="flex justify-center gap-4">
          {returnCategories(filterBy, drilldown).map((category, index) => (
            <div className="flex items-center gap-2" key={`category-${index}`}>
              <div
                className="rounded-full w-4 h-4"
                style={{
                  minWidth: "1rem",
                  backgroundColor: drilldown
                    ? cachedCategoryColor[category]
                    : (colorDictionary[filterBy][
                        category.toLowerCase()
                      ] as string),
                }}
              ></div>
              {category}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function getCategoryModalDataForMonth(entry: AttackPerMonth) {
  return (
    <>
      <b className="text-center">{entry.name}</b>
      <br />
      <b className="text-center">
        {entry.type}: {entry.y}
      </b>
    </>
  );
}

function returnCategories(filterBy: string, drilldown: string): string[] {
  if (drilldown) {
    return Object.keys(cachedCategoryColor);
  }
  switch (filterBy) {
    case "SubSector":
      return returnSubSectors();
    case "AttackCategory":
      return returnAttackCategories();
    case "AttackType":
      return returnAttackTypes();
    case "Region":
      return returnRegions();
    default:
      return returnSubSectors();
  }
}

function roundUp5(value: number) {
  return Math.ceil((value + 0.1) / 5) * 5;
}

function getCategory(attack: Attack, filterBy: string, drilldown: string) {
  if (drilldown) {
    switch (filterBy) {
      case "SubSector":
        return attack.involvedOrganization[0].organizationType;
      case "AttackCategory":
        return attack.subType;
      default:
        return attack.involvedOrganization[0].organizationType;
    }
  } else {
    switch (filterBy) {
      case "SubSector":
        return attack.involvedOrganization[0].primarySubSector;
      case "AttackCategory":
        return attack.type;
      case "AttackType":
        return attack.subType;
      case "Region":
        return attack.hasPrimaryLocation[0].region;
      default:
        return attack.involvedOrganization[0].primarySubSector;
    }
  }
}

function populateCategoryColor(
  categoryColor: Record<string, string>,
  categoryIndex: Record<string, number>,
  filterBy: string,
  drilldown: string,
  categoryType: string
) {
  if (drilldown) {
    categoryIndex[drilldown] = categoryIndex[drilldown] ?? 0;
    categoryIndex[categoryType] =
      categoryIndex[categoryType] ?? categoryIndex[drilldown]++;

    categoryColor[categoryType] =
      categoryColor[categoryType] ??
      (colorDictionary[filterBy].gradientOfColors as Record<string, string[]>)[
        drilldown.toLowerCase()
      ][categoryIndex[categoryType]];
    return categoryColor[categoryType];
  } else {
    categoryIndex[categoryType] = categoryIndex[categoryType] ?? 0;
    categoryColor[categoryType] =
      categoryColor[categoryType] ??
      (colorDictionary[filterBy][categoryType.toLowerCase()] as string);
    return categoryColor[categoryType];
  }
}

interface AttacksPerMonth {
  data: Record<string, AttackPerMonth>[];
  types: string[];
  monthlyMax: number;
}

interface AttackPerMonth {
  x: Date;
  y: number;
  name: string;
  type: string;
  parentType: string;
  ref: any;
  color: string;
}

function filterAttacks(attacks: Attack[], filterBy: string, drilldown: string) {
  if (drilldown) {
    switch (filterBy) {
      case "SubSector":
        return attacks.filter(
          (attack) =>
            attack.involvedOrganization[0].primarySubSector === drilldown
        );
      case "AttackCategory":
        return attacks.filter((attack) => attack.type === drilldown);
      default:
        return attacks.filter(
          (attack) =>
            attack.involvedOrganization[0].primarySubSector === drilldown
        );
    }
  }
  return attacks;
}

function sortTypes(types: string[], filterBy: string) {
  const sortingArray = returnCategories(filterBy, "");
  return types.sort(
    (a, b) => sortingArray.indexOf(b) - sortingArray.indexOf(a)
  );
}

function getSeries(attacks: Attack[], filterBy: string, drilldown: string) {
  let series: AttacksPerMonth = {
    types: [],
    data: [],
    monthlyMax: 0,
  };

  let attacksPerMonth: Record<string, Record<string, AttackPerMonth>> = {};
  let categoryColor: Record<string, string> = {};
  let categoryIndex: Record<string, number> = {};

  let categoryType: string;

  if (attacks) {
    let filteredAttacks = filterAttacks(attacks, filterBy, drilldown);

    filteredAttacks.forEach((attack) => {
      let eventDate = Moment(attack.eventDateFrom);

      categoryType = getCategory(attack, filterBy, drilldown);

      const monthAndYear = eventDate.format("YYYY-MM");
      const dateMonth = eventDate.format("YYYY-MM-01");

      const color = populateCategoryColor(
        categoryColor,
        categoryIndex,
        filterBy,
        drilldown,
        categoryType
      );

      attacksPerMonth[dateMonth] = attacksPerMonth[dateMonth] ?? {};
      attacksPerMonth[dateMonth][categoryType] = attacksPerMonth[dateMonth][
        categoryType
      ] ?? {
        y: 0,
        x: Moment(dateMonth).startOf("day").toDate(),
        name: monthAndYear,
        type: categoryType,
        parentType: drilldown,
        ref: React.createRef(),
        color,
      };
      attacksPerMonth[dateMonth][categoryType].y++;
    });

    if (drilldown) {
      cachedCategoryColor = categoryColor;
    }

    Object.entries(attacksPerMonth).reduce(
      (acc: AttacksPerMonth, [i, value]) => {
        let keys = Object.keys(value);
        acc.types = Array.from(new Set([...acc.types, ...keys]));
        acc.data = [...acc.data, value];
        return acc;
      },
      series
    );
    series.data.sort(
      (a: Record<string, AttackPerMonth>, b: Record<string, AttackPerMonth>) =>
        Object.values(a)[0].x.getTime() - Object.values(b)[0].x.getTime()
    );

    if (!drilldown) {
      sortTypes(series.types, filterBy);
    }

    series.monthlyMax = series.data.reduce((acc, attack) => {
      let monthlyValue = Object.values(attack).reduce((monthlyAcc, entry) => {
        monthlyAcc += entry.y;
        return monthlyAcc;
      }, 0);
      return acc > monthlyValue ? acc : monthlyValue;
    }, series.monthlyMax);
  }

  return series;
}

export default TimeBarChartWithDrillDownByFilter;
