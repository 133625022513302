import React from "react";
import "./AttackPage.css";
import { formatDays, formatNumber } from "../../utils/format";
import { Attack } from "../../diagrams/types";

const TYPES = {
  Ransomware: "ransomware",
};

const icons: Record<string, string> = {
  "compromise account": "/images/attack-account-takeover.png",
  "data theft": "/images/attack-data-theft.png",
  ddos: "/images/attack-data-theft.png",
  "other malware": "/images/attack-malware-other.png",
  ransomware: "/images/attack-ransomware.png",
  "ransomware (unconfirmed)": "/images/attack-ransomware.png",
  unknown: "/images/attack-other.png",
};

interface Props {
  attack: Attack;
}

function getSourceLevels(attack: Attack) {
  const sourceLevels: Array<string> = Array.from(
    new Set(
      attack.cited.reduce((acc: Array<string>, source) => {
        return [...acc, source.level];
      }, [])
    )
  );
  sourceLevels.sort((a, b) => a.localeCompare(b));
  return sourceLevels;
}

export default function AttackPage(props: Props) {
  const [state, setState] = React.useState({
    tab: 0,
  });

  const sourceLevels: Array<string> = getSourceLevels(props.attack);

  return (
    <div className="single-attack">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`btn btn-link nav-link ${
              state.tab === 0 ? "active" : ""
            }`}
            onClick={() => setState({ ...state, tab: 0 })}
          >
            Incident
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`btn btn-link nav-link ${
              state.tab === 1 ? "active" : ""
            }`}
            onClick={() => setState({ ...state, tab: 1 })}
          >
            Impact
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`btn btn-link nav-link ${
              state.tab === 2 ? "active" : ""
            }`}
            onClick={() => setState({ ...state, tab: 2 })}
          >
            Sources
          </button>
        </li>
      </ul>

      <div className="row">
        {state.tab === 0 && (
          <div className="col-md-12 col-sm-12 position-relative">
            {icons[props.attack.subType.toLowerCase()] && (
              <img
                className="w-18 h-18 position-absolute top-0 right-10"
                src={icons[props.attack.subType.toLowerCase()]}
                alt={props.attack.subType}
              />
            )}

            <h3 className="text-center">Incident Summary</h3>
            <dl>
              <dt>Date</dt>
              <dd>{props.attack.eventDateFrom.split("T")[0]}</dd>
              <dt>Incident Type</dt>
              <dd>{props.attack.subType}</dd>
              <dt>Incident Certainty</dt>
              <dd>{props.attack.eventConfidence}</dd>
              <dt>Country</dt>
              <dd className="flex align-items-center">
                <img
                  alt={`Flag for ${props.attack.hasPrimaryLocation[0].country}`}
                  className="round-flag"
                  src={`./data/countries/img/${props.attack.hasPrimaryLocation[0].countryAbbreviation.toLowerCase()}.svg`}
                />{" "}
                <div className="ml-2">
                  {props.attack.hasPrimaryLocation[0].country}
                </div>
              </dd>
              <dt>Incident Description</dt>
              <dd>{props.attack.description}</dd>
            </dl>

            <hr />

            <h3 className="text-center mt-3">Target Details</h3>
            {props.attack.involvedOrganization.map((org, index) => (
              <React.Fragment key={`details-${index}`}>
                {index > 0 && <hr />}
                <dl>
                  <dt>Sector</dt>
                  <dd>{org.primarySector}</dd>
                  <dt>Sub-Sector</dt>
                  <dd>{org.primarySubSector}</dd>
                  <dt>Organization Type</dt>
                  <dd>{org.organizationType}</dd>
                  <dt>Impacted Sites</dt>
                  <dd>{props.attack.consequences[0].numSitesAffected}</dd>
                </dl>
              </React.Fragment>
            ))}
          </div>
        )}

        {state.tab === 1 && (
          <div className="col-md-12 col-sm-12">
            <div className="columns">
              <div>
                <h4>on Individuals</h4>
                <dl>
                  <dt>Breach Size</dt>
                  <dd>
                    {props.attack.consequences[0].nbRecords
                      ? formatNumber(props.attack.consequences[0].nbRecords)
                      : "Unknown"}
                  </dd>
                  <dt>Appointments Canceled</dt>
                  <dd>
                    {props.attack.consequences[0].hcAppointmentsCancelled}
                  </dd>
                  <dt>Patients Redirected</dt>
                  <dd>{props.attack.consequences[0].hcPatientsRedirected}</dd>
                </dl>
              </div>
              <div>
                <h4>on Organization</h4>
                <dl>
                  <dt>Impact Duration</dt>
                  <dd>
                    {props.attack.consequences[0].opImpactDuration
                      ? formatDays(
                          props.attack.consequences[0].opImpactDuration
                        )
                      : "Unknown"}
                  </dd>
                  <dt>Data exposed / leaked</dt>
                  <dd>
                    {props.attack.consequences[0].exposedLeaked
                      ? formatDays(props.attack.consequences[0].exposedLeaked)
                      : "Unknown"}
                  </dd>
                  <dt>Systems Offline</dt>
                  <dd>
                    {props.attack.consequences[0].hcSystemsOffline
                      ? props.attack.consequences[0].hcSystemsOffline
                      : "Unknown"}
                  </dd>
                  {props.attack.subType.toLowerCase() === TYPES.Ransomware && (
                    <>
                      <dt>Ransom Requested</dt>
                      <dd>
                        {
                          props.attack.consequences[0].financial[0]
                            .ransomRequested
                        }
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
            <h5>Impact Description</h5>
            <div>{props.attack.consequences[0].description}</div>
            {props.attack.consequences[0].otherInfo && (
              <>
                <h5>Type of data breached</h5>
                <div>{props.attack.consequences[0].otherInfo}</div>
              </>
            )}
          </div>
        )}

        {state.tab === 2 && (
          <div className="col-md-12 col-sm-12">
            <h3>Data Sources</h3>
            {sourceLevels.map((sourceLevel, levelIndex) => {
              return props.attack.cited
                .filter(
                  (entry) =>
                    entry.level.toLowerCase() === sourceLevel.toLowerCase()
                )
                .map((source, index) => (
                  <div key={`source-${levelIndex}-${index}`}>
                    {index === 0 && (
                      <>
                        <hr />
                        <h4 className="source-level-title">{sourceLevel}</h4>
                      </>
                    )}

                    <dl>
                      <dt>Source</dt>
                      <dd>
                        <a href={source.URL} rel="noreferrer" target="_blank">
                          {source.sourceName}
                        </a>
                      </dd>
                      <dt>Date Reported</dt>
                      {source.publicationDate.split("T")[0]}
                    </dl>
                  </div>
                ));
            })}
          </div>
        )}
      </div>
    </div>
  );
}
