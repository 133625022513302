import React from "react";
import { useInView } from "react-intersection-observer";

export enum TeaserLayout {
  ImageOnLeft = "image-on-left",
  ImageOnRight = "image-on-right",
}

export interface TeaserProps {
  image: string;
  title: string;
  description: string;
  layout?: TeaserLayout;
}

const defaultProps: Partial<TeaserProps> = {
  layout: TeaserLayout.ImageOnLeft,
};

export default function Teaser(props: TeaserProps) {
  const { ref, inView } = useInView({});

  const p = Object.assign({}, defaultProps, props);

  const image = (
    <img className="w-full md:w-32 h-auto" src={p.image} alt={p.title} />
  );

  return (
    <div className="grid grid-cols-2 items-center gap-4 m-8" ref={ref}>
      {p.layout === TeaserLayout.ImageOnLeft && (
        <div className="flex justify-end">{image}</div>
      )}
      <div
        className={
          p.layout === TeaserLayout.ImageOnLeft ? "text-left" : "text-right"
        }
      >
        <div
          className={`text-base md:text-xl text-cpi transition-opacity duration-500 ease-in-out ${
            inView ? "opacity-100" : "opacity-0"
          }`}
        >
          {p.title}
        </div>
        <div
          className={`text-base md:text-xl color-text transition-opacity duration-1000 ease-in-out ${
            inView ? "opacity-100" : "opacity-0"
          }`}
        >
          {p.description}
        </div>
      </div>
      {p.layout === TeaserLayout.ImageOnRight && (
        <div className="flex justify-start">{image}</div>
      )}
    </div>
  );
}
