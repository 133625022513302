import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/sunburst")(Highcharts);

function returnChartOptions(data) {
  return {
    chart: {},

    // Let the center circle be transparent
    colors: ["transparent"].concat(Highcharts.getOptions().colors),

    title: {
      text: "",
    },

    subtitle: {},

    series: [
      {
        type: "sunburst",
        data: data,
        allowDrillToNode: true,
        cursor: "pointer",
        dataLabels: {
          format: "{point.name}",
          filter: {
            property: "innerArcLength",
            operator: ">",
            value: 16,
          },
          rotationMode: "circular",
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: "outerArcLength",
                operator: ">",
                value: 64,
              },
            },
          },
          {
            level: 2,
            colorByPoint: true,
          },
          {
            level: 3,
            /*colorVariation: {
              key: "brightness",
              to: -0.5,
            },*/
          },
          {
            level: 4,
            /*colorVariation: {
              key: "brightness",
              to: 0.5,
            },*/
          },
        ],
      },
    ],

    exporting: {
      enabled: false,
    },

    tooltip: {
      headerFormat: "",
      pointFormat:
        "Number of incidents in <b>{point.name}</b>: <b>{point.value}</b>",
    },
  };
}

function getRegions(attacks) {
  return [
    ...new Set(attacks.map((attack) => attack.hasPrimaryLocation[0].region)),
  ];
}

function getSubRegionsByRegion(attacks, region) {
  return [
    ...new Set(
      attacks
        .filter((attack) => attack.hasPrimaryLocation[0].region === region)
        .map((attack) => attack.hasPrimaryLocation[0].subRegion)
    ),
  ];
}

function getSubRegionWithCountries(attacks, region, subRegions) {
  return subRegions.reduce((acc2, subRegion) => {
    acc2[subRegion] = {
      id: subRegion,
      parent: region,
      name: subRegion,
    };

    const countrySumBySubRegion = getCountrySumByRegionAndSubRegion(
      attacks,
      region,
      subRegion
    );

    return {
      ...acc2,
      ...countrySumBySubRegion,
    };
  }, {});
}

function getCountrySumByRegionAndSubRegion(attacks, region, subRegion) {
  return attacks
    .filter(
      (attack) =>
        attack.hasPrimaryLocation[0].region === region &&
        attack.hasPrimaryLocation[0].subRegion === subRegion
    )
    .reduce((acc3, item) => {
      const country = item.hasPrimaryLocation[0].country;
      acc3[country] = acc3[country] ?? {
        id: country,
        value: 0,
        name: country,
        parent: subRegion,
      };
      acc3[country].value++;

      return acc3;
    }, {});
}

function getRegionsSubRegionsAndCountries(attacks, regions) {
  const theWorld = "The World";
  let i = 0;
  const colors = ["#003E97", "#FFC26E", "#2D78FF", "#7D6ED6", "#F78E48"];
  return regions.reduce(
    (acc, region) => {
      acc[region] = {
        id: region,
        parent: theWorld,
        name: region,
        color: colors[i],
      };
      i++;

      const subRegions = getSubRegionsByRegion(attacks, region);

      const subRegionWithCountries = getSubRegionWithCountries(
        attacks,
        region,
        subRegions
      );

      return {
        ...acc,
        ...subRegionWithCountries,
      };
    },
    {
      beginning: {
        id: theWorld,
        name: theWorld,
      },
    }
  );
}

function getSeries(attacks) {
  const regions = getRegions(attacks);
  const regionsSubRegionsAndCountries = getRegionsSubRegionsAndCountries(
    attacks,
    regions
  );

  return Object.values(regionsSubRegionsAndCountries);
}

class SunBurstChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: returnChartOptions(this.categories),
      shouldRender: true,
    };
  }

  refreshData() {
    const attacks = this.props.attacks;
    let data = [];

    if (attacks) {
      data = getSeries(attacks);
    }

    setTimeout(() => {
      this.setState({
        ...this.state,
        shouldRender: true,
      });
    }, 1000);
    this.setState({
      chartOptions: returnChartOptions(data),
      shouldRender: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      this.props.attacks &&
      prevProps.attacks !== this.props.attacks
    ) {
      this.refreshData();
    }
  }

  render() {
    const { chartOptions, shouldRender } = this.state;

    return (
      <div className="highcharts-outer-container">
        {shouldRender && (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        )}
      </div>
    );
  }
}

export default SunBurstChart;
