import React, { PropsWithChildren } from "react";
import { overrideClassNames } from "../utils";

interface Props {
  className?: string;
}

const defaultClassNames = "my-8";

export default function Chart(props: PropsWithChildren<Props>) {
  return (
    <div className={overrideClassNames(defaultClassNames, props.className)}>
      {props.children}
    </div>
  );
}
