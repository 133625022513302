import { atom } from "recoil";
import { Attack } from "./diagrams/types";

export interface FilterValue {
  label: React.ReactNode;
  shortValue?: string;
  value: string;
}

export interface AttackFilterAtom {
  fromDate: string;
  toDate: string;
  selectedAttackCategories: FilterValue[];
  selectedAttackTypes: FilterValue[];
  selectedRansomwareOperator: FilterValue[];
  selectedSubSectors: FilterValue[];
  selectedOrganizationTypes: FilterValue[];
  selectedRegions: FilterValue[];
  selectedCountries: FilterValue[];
}

export const attackFilterAtom = atom<AttackFilterAtom>({
  key: "attackFilter",
  default: {
    fromDate: "",
    toDate: "",
    selectedAttackCategories: [],
    selectedAttackTypes: [],
    selectedRansomwareOperator: [],
    selectedSubSectors: [],
    selectedOrganizationTypes: [],
    selectedRegions: [],
    selectedCountries: [],
  },
});

export type IncidentsAtom = Attack[];
export const incidentsResultsAtom = atom<IncidentsAtom>({
  key: "incidentsResults",
  default: [],
});
