import React from "react";
import Page from "../../lib/Page";
import Section from "../../lib/Section";
import LinkButton from "../../lib/LinkButton";
import TitleWithNumber from "../../lib/TitleWithNumber";
import A from "../../lib/A";

export default function Act() {
  return (
    <>
      <Page
        hideBottomLinks={true}
        title="Act Now"
        intro={
          <>
            <p>
              There are disparities and gaps in the reporting and availability
              of data on cyberattacks against the healthcare sector, which
              enables them to continue. CIT #HEALTH is a platform that traces
              these attacks in an effort to provide reliable data to inform
              policy making, encourage stronger reporting and transparency
              relating to cyberattacks and to improve both the understanding of
              the threat and the ability to take appropriate action to reduce it
              in the future.
            </p>
            <p>
              The Institute is releasing the beta version now to raise the level
              of urgency and{" "}
              <A href="https://cyberpeaceinstitute.org/call-for-government">
                reiterate the call to stop cyberattacks on healthcare
              </A>
              , highlighting key areas where further action can be taken to
              better protect healthcare. We have also identified several areas
              for potential collaboration to further strengthen and develop the
              platform.
            </p>
          </>
        }
      >
        <Section title="Call to Action">
          <TitleWithNumber number={1}>Call to Governments</TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              Stop attacks on healthcare. Health is a fundamental right and it
              is the states' responsibility to lead the way to protect this
              common good.
            </li>
            <li>
              Take meaningful action, operationalize and enforce norms of
              responsible behavior and international law applicable to the
              healthcare sector. This is critical infrastructure and off-limits
              to attack.
            </li>
            <li>
              Invest resources to ensure the healthcare sector is equipped to
              deal with cyber threats.
            </li>
            <li>
              Contribute to initiatives that bring greater visibility to how
              attacks on the healthcare sector really impact people and the
              provision of care.
            </li>
            <li>
              Ensure accountability for cyberattacks on healthcare, domestically
              and vis-a-vis other nation states. Arrest perpetrators of attacks.
              Ensure a transparent and efficient judicial process to hold
              criminals to account.
            </li>
          </ul>

          <TitleWithNumber number={2}>
            Call to the Healthcare Sector
          </TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              Be aware of current cyber threats and implement defences
              proportionate to them.
            </li>
            <li>
              Conduct vulnerability scanning, assessments, and timely patching
              of systems.
            </li>
            <li>
              Share information about vulnerabilities with all relevant
              stakeholders.
            </li>
            <li>Secure potential attack vectors/endpoints.</li>
            <li>
              Report incidents and their impact on the ability to provide
              healthcare in a timely manner.
            </li>
          </ul>

          <TitleWithNumber number={3}>Call to Industry</TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              Implement security-by-design and security-by-default models for
              healthcare systems and product development across the supply
              chain.
            </li>
            <li>
              Adapt pricing models according to the diversity of resources in
              healthcare to prevent discrepancies arising from those who can
              afford cybersecurity and those that cannot.
            </li>
            <li>
              Sponsor research in technical solutions such as zero-trust
              networks, behavioural authentication and monitoring to improve the
              protection of hospitals and healthcare facilities from
              vulnerabilities in their supply chain.
            </li>
          </ul>
        </Section>

        <Section title="Call for Contributions">
          <p>
            The CyberPeace Institute is continuing to collect data on
            cyberattacks and develop the platform. If you have the capabilities
            to support this endeavour, we would appreciate hearing from you.
            Contact us:{" "}
            <A href="mailto:cit@cyberpeaceinstitute.org">
              cit@cyberpeaceinstitute.org
            </A>
          </p>

          <TitleWithNumber number={1}>
            Can you partner and contribute to the collection of data on
            cyberattacks?
          </TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              Can you contribute to the ongoing technical development of the
              platform?
            </li>
            <li>
              Can you develop an empirical methodology for measuring the human
              and societal impact of cyber incidents?
            </li>
            <li>
              Can you support us in defining a methodology for tracking
              attribution and accountability?
            </li>
            <li>
              Can you donate to support the CyberPeace Institute in its work?
            </li>
          </ul>

          <TitleWithNumber number={2}>
            Are you involved in academic or scientific research?
          </TitleWithNumber>
          <ul className="list-disc m-4">
            <li>Contribute to the collection of additional data.</li>
            <li>
              Work with us to develop an empirical methodology for measuring the
              human and societal impact of cyber incidents.
            </li>
            <li>
              Support us in defining a methodology for tracking attribution and
              accountability.
            </li>
          </ul>

          <TitleWithNumber number={3}>
            Are you a web developer, ux designer, or storyteller ?
          </TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              Use your expertise to help with the technical development and
              usability of the platform.
            </li>
          </ul>

          <TitleWithNumber number={4}>
            Are you a potential donor who believes in our mission?
          </TitleWithNumber>
          <ul className="list-disc m-4">
            <li>
              We conduct critical work for the safety of the public. Invest in
              our collective future. Support cyberspace at peace, for everyone,
              everywhere.
            </li>
          </ul>

          <div className="mt-8">
            <LinkButton
              className="block w-44 ml-auto mr-auto"
              to="mailto:cit@cyberpeaceinstitute.org"
            >
              Contact Us
            </LinkButton>
          </div>
        </Section>

        <Section title="Call for Donations">
          <p>
            Are you a potential donor who believes in our mission? Can you
            donate to support the CyberPeace Institute in its work? Your support
            will make a difference.
          </p>

          <p>
            Our efforts focus on reducing the impact of cyberattacks on people’s
            lives throughout the world. But we can’t do it without you.
          </p>

          <div className="mt-8">
            <LinkButton
              className="block w-44 ml-auto mr-auto"
              to="https://cyberpeaceinstitute.org/donate"
            >
              Donate now
            </LinkButton>
          </div>
        </Section>

        <Section title={<>Call for Testimonials</>}>
          <p>
            Have you been a victim of a cyberattack? Do you want to share your
            story about how cyberattacks against healthcare affected you?
          </p>

          <div className="mt-8">
            <LinkButton
              className="block w-48 ml-auto mr-auto"
              to="mailto:assistance@cyberpeaceinstitute.org"
            >
              Share your Story
            </LinkButton>
          </div>
        </Section>

        <Section title="Contact">
          <p>If you can support us or have a question, please do reach out.</p>

          <div className="mt-8">
            <LinkButton
              className="block w-44 ml-auto mr-auto"
              to="mailto:cit@cyberpeaceinstitute.org"
            >
              Contact Us
            </LinkButton>
          </div>
        </Section>
      </Page>

      <section className="max-w-screen-xl mx-auto my-16 py-8 px-8 md:px-0 grid grid-cols-4 gap-8 text-sm">
        <div className="col-span-4 md:col-span-2">
          <h2 className="font-extrabold">About the CyberPeace Institute</h2>

          <p>
            The CyberPeace Institute is an independent and neutral non
            governmental organization whose mission is to ensure the rights of
            people to security, dignity and equity in cyberspace. The Institute
            works in close collaboration with relevant partners to reduce the
            harms from cyberattacks on people’s lives worldwide, and provide
            assistance. By analyzing cyberattacks, the Institute exposes their
            societal impact, how international laws and norms are being
            violated, and advances responsible behaviour to enforce cyberpeace.
          </p>
        </div>
        <div className="col-span-4 md:col-span-1">
          <h3 className="font-extrabold">Our Address</h3>

          <p>
            Campus Biotech Innovation Park
            <br />
            <br />
            Avenue de Sécheron 15
            <br />
            1202 GENÈVE
            <br />
            Switzerland
          </p>
        </div>
        <div className="col-span-4 md:col-span-1">
          <h3 className="font-extrabold">Contact Us</h3>

          <dl>
            <dt>Media Contact</dt>
            <dd>
              <A href="mailto:media@cyberpeaceinstitute.org">
                media@cyberpeaceinstitute.org
              </A>
            </dd>
            <dt>General Enquiries</dt>
            <dd>
              <A href="mailto:cit@cyberpeaceinstitute.org">
                cit@cyberpeaceinstitute.org
              </A>
            </dd>
          </dl>
        </div>
      </section>
    </>
  );
}
