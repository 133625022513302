import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";

interface Props {
  className?: string;
  items: string[];
  duration?: number;
}

export default function Blink({
  className = "lowercase",
  items,
  duration = 2000,
}: Props) {
  const [index, setIndex] = React.useState(0);
  const [inProp, setInProp] = useState(false);

  const defaultStyle = {
    transition: `opacity ${duration / 2}ms ease-in 0s`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index === items.length - 1 ? 0 : index + 1);
      setInProp(true);
      setTimeout(() => {
        setInProp(false);
      }, duration / 2);
    }, duration);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Transition
      key={index}
      in={inProp}
      timeout={{
        appear: duration / 10,
        enter: 0,
        exit: duration / 10,
      }}
    >
      {(state) => (
        <span
          className={className}
          style={{
            ...defaultStyle,
            // @ts-ignore
            ...transitionStyles[state],
          }}
        >
          {items[index]}
        </span>
      )}
    </Transition>
  );
}
