import countryOptions from "../../utils/data/countries/countries.json";
import React from "react";
import { FilterValue } from "../../atoms";
import { Attack, InvolvedThreatActor } from "../../diagrams/types";

export const attackCategoryOptions: FilterValue[] = [
  { value: "disruptive attack", label: "Disruptive Attack" },
  /*{ value: "data breach", label: "Data Breach" },*/
  { value: "unknown", label: "Unknown" },
];

export const attackTypeOptions: FilterValue[] = [
  { value: "malware (except ransomware)", label: "Malware" },
  { value: "ransomware", label: "Ransomware" },
  /*{ value: "ransomware (unconfirmed)", label: "Ransomware (unconfirmed)" },
  { value: "compromise account", label: "Compromise Account" },
  { value: "data theft", label: "Data Theft" },*/
  { value: "ddos", label: "DDoS" },
  { value: "unknown", label: "Unknown" },
];

export const initialRansomwareOperatorOptions: FilterValue[] = [
  { value: "LockBit", label: "LockBit" },
  { value: "Everest", label: "Everest" },
  { value: "ddos", label: "DDoS" },
  { value: "unknown", label: "Unknown" },
];

export const subSectorOptions: FilterValue[] = [
  {
    value: "medical manufacturing & development",
    label: "Medical Manufacturing & Development",
  },
  { value: "patient care services", label: "Patient care services" },
  { value: "pharmaceuticals", label: "Pharmaceuticals" },
  { value: "other", label: "Other" },
];

export const initialOrganizationTypesOptions: FilterValue[] = [
  { value: "ehr / pm vendor", label: "EHR / PM Vendor" },
  { value: "hospital", label: "Hospital" },
  { value: "medical specialist", label: "Medical specialist" },
  { value: "clinic", label: "Clinic" },
  { value: "care facility", label: "Care Facility" },
  {
    value: "laboratories and diagnostics center",
    label: "Laboratories And Diagnostics Center",
  },
  { value: "healthcare network", label: "Healthcare Network" },
  { value: "national health system", label: "National Health System" },
  { value: "hospital", label: "Hospital" },
];

export const regionOptions: FilterValue[] = [
  { value: "africa", label: "Africa" },
  { value: "americas", label: "Americas" },
  { value: "asia", label: "Asia" },
  { value: "europe", label: "Europe" },
  { value: "oceania", label: "Oceania" },
];

export const initialCountryOptions: FilterValue[] = countryOptions.map(
  function (object) {
    return {
      label: <div>{object.label}</div>,
      value: object.label,
      shortValue: object.value,
    };
  }
);

export function isRansomwareOperator(threatActor: InvolvedThreatActor) {
  return threatActor.type.toLowerCase() === "ransomware operator";
}

export function getRansomwareOperators(attacks: Attack[]) {
  return Array.from(
    new Set(
      attacks
        .flatMap((attack) => attack.involvedThreatActor)
        .filter((threatActor) => isRansomwareOperator(threatActor))
        .map((threatActor) => threatActor.name)
    )
  ).sort((a, b) => a.localeCompare(b));
}

export function returnAttackCategories() {
  return attackCategoryOptions.reduce((acc: string[], entry: FilterValue) => {
    return [...acc, entry.label as string];
  }, []);
}

export function returnAttackTypes() {
  return attackTypeOptions.reduce((acc: string[], entry: FilterValue) => {
    return [...acc, entry.label as string];
  }, []);
}

export function returnRegions() {
  return regionOptions.reduce((acc: string[], entry: FilterValue) => {
    return [...acc, entry.label as string];
  }, []);
}

export function returnSubSectors() {
  return subSectorOptions.reduce((acc: string[], entry: FilterValue) => {
    return [...acc, entry.label as string];
  }, []);
}
