import React, { PropsWithChildren } from "react";
import LinkButton from "./LinkButton";

function Paragraph(props: PropsWithChildren<{}>) {
  return (
    <p className="text-center text-base text-white font-bold p-0 my-2 max-w-3xl">
      {props.children}
    </p>
  );
}
export default function OutOfDate() {
  return (
    <div className="bg-sec content-center flex flex-column align-items-center overflow-y-auto">
      <Paragraph>
        The last update of the Cyber Incident Tracer #HEALTH was done on
        30.09.2022. Since then, we are maintaining the platform, while
        developing other Cyber Incident Tracers (#UKRAINE and #HUMANITARIAN).
      </Paragraph>
      <Paragraph>
        Your support is key to get a more thorough understanding of the
        frequency and harm of cyberattacks against the healthcare sector and to
        help protecting patients against this threat.
      </Paragraph>
      <Paragraph>
        If you would like to partner with us and contribute to the collection,
        processing and updating of new data to feed the Cyber Incident Tracer
        #HEALTH, please contact us.
      </Paragraph>
      <div className="my-8">
        <LinkButton
          className="w-64 px-8 md:px-14 py-3 md:py-4 font-extrabold bg-cpi text-white hover:text-cpi hover:border-cpi text-center"
          to="mailto:cit@cyberpeaceinstitute.org"
        >
          Contact Us
        </LinkButton>
      </div>
    </div>
  );
}
