import React from "react";
import Page from "../../lib/Page";
import Section from "../../lib/Section";
import A from "../../lib/A";

export default function About() {
  return (
    <Page
      intro={
        <p>
          The CyberPeace Institute is an independent and neutral non
          governmental organization whose mission is to ensure the rights of
          people to security, dignity and equity in cyberspace. The Institute
          works in close collaboration with relevant partners to reduce the
          harms from cyberattacks on people’s lives worldwide, and provide
          assistance. By analyzing cyberattacks, the Institute exposes their
          societal impact, how international laws and norms are being violated,
          and advances responsible behaviour to enforce cyberpeace.
        </p>
      }
      title="About CIT"
    >
      <Section title="Definition of the Platform">
        <p>
          The Cyber Incident Tracer (CIT) #HEALTH is a data platform that
          presents cyberattacks on the healthcare sector which disrupt the
          delivery of healthcare and subsequent impact on patients, healthcare
          professionals and facilities. The platform brings greater visibility
          to the scale of the problem and how such attacks impact people and the
          provision of care.
        </p>

        <p>
          The data is displayed through filterable data visualizations and an
          aggregated searchable data table in which each incident can be
          expanded to explore specific details relating to the sources, incident
          itself, the targeted organization, and the impact of the incident.
        </p>

        <p>
          The CyberPeace Institute developed CIT #HEALTH following its{" "}
          <A href="https://cyberpeaceinstitute.org/publications/sar001-healthcare/">
            Strategic Analysis Report released in March 2021
          </A>
          , entitled{" "}
          <A href="https://cpi.link/sar001">
            Playing with Lives: Cyberattacks on Healthcare are Attacks on People
          </A>
          . This report made several key recommendations related to the existing
          data gap on cyberattacks against the healthcare sector, and the CIT
          #HEALTH platform is a step to remedy this issue by improving
          visibility of such attacks.
        </p>

        <p>
          CIT #HEALTH will be a useful resource for a wide range of audiences
          from policy makers, healthcare professionals, cybersecurity experts,
          civil society organizations to the media. It will be updated on a
          quarterly basis, based on data accessibility.
        </p>

        <p>
          If you believe you can contribute to the data contained in CIT
          #HEALTH, please reach out to us at{" "}
          <A href="mailto:cit@cyberpeaceinstitute.org">
            cit@cyberpeaceinstitute.org
          </A>{" "}
          to see how we can collaborate.
        </p>

        <p>
          If you are a healthcare organization and have been a target of
          cyberattacks, the CyberPeace Institute may be able to help you. Please
          reach out to our Assistance team at{" "}
          <A href="mailto:assistance@cyberpeaceinstitute.org">
            assistance@cyberpeaceinstitute.org
          </A>{" "}
          in order to learn more about our free services.
        </p>
      </Section>
    </Page>
  );
}
