import React from "react";
import Moment from "moment";

import "./FilterPeriods.css";
import CountUp from "react-countup";
import { Attack } from "../types";
import { getFormatedDate } from "../../utils/format";
import { getRansomwareOperators } from "../../lib/filters/utils";

interface Props {
  attacks: Attack[];
  lastPublished: string;
}

function FilterPeriods(props: Props) {
  const current = returnPeriod(props.attacks);

  const isPrevEnabled = false;

  return (
    <div className="filter-periods">
      {isPrevEnabled && (
        <div className="filter-period previous">
          <div className="filter-period-header">
            Previous period: 1.7.2021 - 1.8.2021
          </div>

          <div className="filter-period-data">32</div>
          <div className="filter-period-data">8</div>
          <div className="filter-period-data-subtitle">total incidents</div>
          <div className="filter-period-data-subtitle">incidents per week</div>

          <div className="comparison">
            <div className="number">+21%</div>
            <div className="arrow">
              <img src={"./data/icons/arrow.svg"} alt="" />
            </div>
          </div>
        </div>
      )}
      <div className="filter-period current">
        <div className="filter-period-header">
          Selected period: {current.start} <strong>-</strong> {current.end}
        </div>

        <div className="filter-period-data">
          <CountUp duration={1} end={current.totalAttacks} start={0} />
        </div>

        <div className="filter-period-data">
          <CountUp
            duration={1}
            end={
              current.attacksPerWeek === Infinity ? 0 : current.attacksPerWeek
            }
            start={0}
            decimals={1}
          />
        </div>

        <div className="filter-period-data">
          <CountUp duration={1} end={current.affectedCountries} start={0} />
        </div>

        <div className="filter-period-data">
          <CountUp duration={1} end={current.ransomwareOperators} start={0} />
        </div>

        <div className="filter-period-data-subtitle">total incidents</div>
        <div className="filter-period-data-subtitle">incidents per week</div>
        <div className="filter-period-data-subtitle">countries</div>
        <div className="filter-period-data-subtitle">ransom operators</div>

        <div className="filter-period-footer">
          Last updated: {getFormatedDate(props.lastPublished)}
          <br />
          <small>* updates on a quarterly basis</small>
        </div>
      </div>
    </div>
  );
}

function returnPeriod(attacks: Attack[]) {
  const period = initPeriod();

  if (!attacks || !attacks.length) {
    return period;
  }

  const numOfWeeks = getNumOfWeeks(attacks);
  const average = Math.round((attacks.length / numOfWeeks) * 10) / 10;

  period.end = getFormatedDate(attacks[0].eventDateFrom);
  period.start = getFormatedDate(attacks[attacks.length - 1].eventDateFrom);
  period.totalAttacks = attacks.length;
  period.attacksPerWeek = average;
  period.affectedCountries = getAffectedCountries(attacks);
  period.ransomwareOperators = countRansomwareOperators(attacks);

  return period;
}

function getAffectedCountries(attacks: Attack[]): number {
  return new Set(
    attacks.map((attack) => attack.hasPrimaryLocation[0].countryAbbreviation)
  ).size;
}

function countRansomwareOperators(attacks: Attack[]): number {
  return getRansomwareOperators(attacks).length;
}

function getNumOfWeeks(totalAttacks: Attack[]) {
  let startDate = Moment(totalAttacks[0].eventDateFrom);
  let endDate = Moment(totalAttacks[totalAttacks.length - 1].eventDateFrom);
  return startDate.diff(endDate, "week");
}

function initPeriod() {
  return {
    start: "1.1.2021",
    end: "1.1.2022",
    totalAttacks: 0,
    attacksPerWeek: 0,
    affectedCountries: 0,
    ransomwareOperators: 0,
  };
}

export default FilterPeriods;
