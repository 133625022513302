import React from "react";
import ReactLogo from "../icons/CyberPeace-Logo-White-notext.png";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-burrito w-full">
      <footer className="footer-site max-w-full xl:max-w-7xl grid mt-16 ml-auto mr-auto py-16 text-sm text-white items-center gap-4">
        <div className="text-center">
          <p className="md:text-left">
            <strong>Contact Us</strong>
          </p>
          <dl className="md:text-left">
            <dt>
              <strong>Media Contact</strong>
            </dt>
            <dd>
              <a
                className="hover:text-cpi"
                href="mailto:media@cyberpeaceinstitute.org"
              >
                media@cyberpeaceinstitute.org
              </a>
            </dd>
            <dt>
              <strong>General Enquiries</strong>
            </dt>
            <dd>
              <a
                className="hover:text-cpi"
                href="mailto:cit@cyberpeaceinstitute.org"
              >
                cit@cyberpeaceinstitute.org
              </a>
            </dd>
          </dl>
        </div>

        <div></div>

        <div className="footer-logo flex justify-center">
          <img className="h-16 w-16" src={ReactLogo} alt="Home" />
        </div>

        <div className="footer-copyright text-xs flex flex-col items-center md:items-start">
          <div>© CyberPeace Institute 2023.</div>
          <div>All rights reserved.</div>
        </div>

        <div className="footer-social flex flex-col items-center md:items-end">
          <div className="flex">
            <a
              className="footer-icon"
              href="https://twitter.com/cyberpeaceinst"
              rel="noreferrer"
              target="_blank"
              title="Join us on Twitter"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className="footer-icon"
              href="https://www.facebook.com/CyberpeaceInstitute/"
              rel="noreferrer"
              target="_blank"
              title="Join us on Facebook"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              className="footer-icon"
              href="https://www.instagram.com/cyberpeaceinst/"
              rel="noreferrer"
              target="_blank"
              title="Join us on Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="footer-icon"
              href="https://www.youtube.com/channel/UCWanCbSeKGPMUBwuUkzYxbw"
              rel="noreferrer"
              target="_blank"
              title="Join us on YouTube"
            >
              <i className="fab fa-youtube" />
            </a>
            <a
              className="footer-icon"
              href="https://www.linkedin.com/company/cyberpeace-institute"
              rel="noreferrer"
              target="_blank"
              title="Join us on LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
          {/*<div>Privacy Policy</div>*/}
        </div>
      </footer>
    </div>
  );
}
