import React from "react";
import { useRecoilState } from "recoil";
import {
  AttackFilterAtom,
  attackFilterAtom,
  FilterValue,
  IncidentsAtom,
  incidentsResultsAtom,
} from "./atoms";
import { ValueType } from "react-select";
import DatePicker from "react-datepicker";
import debounce from "lodash/debounce";

import "react-datepicker/dist/react-datepicker.css";
import "./FilterForm.css";
import { Attack } from "./diagrams/types";
import FilterValueSelect from "./lib/filters/FilterValueSelect";
import {
  attackTypeOptions,
  getRansomwareOperators,
  initialCountryOptions,
  initialOrganizationTypesOptions,
  initialRansomwareOperatorOptions,
  regionOptions,
  subSectorOptions,
} from "./lib/filters/utils";

function getOrganizationTypes(attacks: Attack[]) {
  return Array.from(
    new Set(
      attacks.map((attack) => attack.involvedOrganization[0].organizationType)
    )
  ).sort((a, b) => a.localeCompare(b));
}

function returnSelectedCountries(incidents: IncidentsAtom) {
  if (incidents.length === 0) {
    return initialCountryOptions;
  } else {
    const selectedCountries = Array.from(
      new Set(
        incidents.flatMap((incident: Attack) =>
          initialCountryOptions.filter(
            (country) =>
              country?.shortValue?.toLowerCase() ===
              incident.hasPrimaryLocation[0].countryAbbreviation.toLowerCase()
          )
        )
      )
    );
    selectedCountries.sort((a, b) => a.value.localeCompare(b.value));

    return selectedCountries;
  }
}

function returnSelectedOrganizationTypes(incidents: IncidentsAtom) {
  if (incidents.length === 0) {
    return initialOrganizationTypesOptions;
  } else {
    const orgTypes = getOrganizationTypes(incidents);
    return [
      ...orgTypes.map((orgType) => ({
        value: orgType.toLowerCase(),
        label: orgType,
      })),
    ];
  }
}

function returnSelectedRansomwareOperators(incidents: IncidentsAtom) {
  if (incidents.length === 0) {
    return initialRansomwareOperatorOptions;
  } else {
    const operators = getRansomwareOperators(incidents);
    return [
      ...operators.map((operator) => ({
        value: operator.toLowerCase(),
        label: operator,
      })),
    ];
  }
}

export default function FilterForm() {
  const [incidents] = useRecoilState<IncidentsAtom>(incidentsResultsAtom);

  const [attackFilter, setAttackFilter] = useRecoilState(attackFilterAtom);

  const filteredCountryOptions = returnSelectedCountries(incidents);

  const organizationTypesOptions = returnSelectedOrganizationTypes(incidents);

  const ransomwareOperatorOptions =
    returnSelectedRansomwareOperators(incidents);

  const handleFilterValueChange =
    (key: keyof AttackFilterAtom) =>
    (filters: ValueType<FilterValue, true>) => {
      setAttackFilter({
        ...attackFilter,
        [key]: filters,
      });
    };

  const handleFilterStringChange =
    (key: keyof Pick<AttackFilterAtom, "fromDate" | "toDate">) =>
    (filters: Date) => {
      const debouncedFilter = debounce(() => {
        setAttackFilter({
          ...attackFilter,
          [key]: filters,
        });
      }, 500);
      debouncedFilter();
    };

  const resetFilter = () => {
    setAttackFilter({
      fromDate: "",
      toDate: "",
      selectedAttackCategories: [],
      selectedAttackTypes: [],
      selectedRansomwareOperator: [],
      selectedSubSectors: [],
      selectedOrganizationTypes: [],
      selectedRegions: [],
      selectedCountries: [],
    });
  };

  return (
    <div className="sticky-filter">
      <div className={`filter-bar-container`}>
        <form className="filter-form">
          <div className="form-field-container flex flex-col md:flex-row">
            <div className="form-element flex items-start md:flex-col text-xs md:text-base">
              <FilterValueSelect
                name="selectedAttackTypes"
                value={attackFilter.selectedAttackTypes}
                onChange={handleFilterValueChange("selectedAttackTypes")}
                placeholder={"Incident Type"}
                options={attackTypeOptions}
              />

              <FilterValueSelect
                name="selectedRansomwareOperator"
                value={attackFilter.selectedRansomwareOperator}
                onChange={handleFilterValueChange("selectedRansomwareOperator")}
                placeholder={"Ransom Operator"}
                options={ransomwareOperatorOptions}
              />
            </div>

            <div className="form-element flex items-start md:flex-col text-xs md:text-base">
              <FilterValueSelect
                name="selectedSubSectors"
                value={attackFilter.selectedSubSectors}
                onChange={handleFilterValueChange("selectedSubSectors")}
                placeholder={"Sub-Sector"}
                options={subSectorOptions}
              />

              <FilterValueSelect
                name="selectedOrganizationTypes"
                value={attackFilter.selectedOrganizationTypes}
                onChange={handleFilterValueChange("selectedOrganizationTypes")}
                placeholder={"Organization Type"}
                options={organizationTypesOptions}
              />
            </div>

            <div className="form-element flex items-start md:flex-col text-xs md:text-base">
              <FilterValueSelect
                name="selectedRegions"
                value={attackFilter.selectedRegions}
                onChange={handleFilterValueChange("selectedRegions")}
                placeholder={"Region"}
                options={regionOptions}
              />

              <FilterValueSelect
                name="selectedCountries"
                value={attackFilter.selectedCountries}
                onChange={handleFilterValueChange("selectedCountries")}
                placeholder={"Country"}
                options={filteredCountryOptions}
              />
            </div>

            <div className="date-input-fields px-2 grid grid-cols-2">
              <DatePicker
                className={`form-element ${
                  attackFilter.fromDate ? "" : "empty-date"
                }`}
                id="fromDate"
                selected={
                  attackFilter.fromDate ? new Date(attackFilter.fromDate) : null
                }
                dateFormat="yyyy/MM/dd"
                onChange={handleFilterStringChange("fromDate")}
                placeholderText="From"
              />

              <DatePicker
                className={`form-element ${
                  attackFilter.toDate ? "" : "empty-date"
                }`}
                id="toDate"
                selected={
                  attackFilter.toDate ? new Date(attackFilter.toDate) : null
                }
                dateFormat="yyyy/MM/dd"
                onChange={handleFilterStringChange("toDate")}
                placeholderText="To"
              />

              <div className="form-element clear-form col-span-2">
                <div className="clear-form-button" onClick={resetFilter}>
                  Reset
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
