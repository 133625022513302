import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import DataTableGlobalFilter from "./DataTableGlobalFilter";
import DataTablePagination from "./DataTablePagination";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DataTable.css";

interface Props<T extends RowData> {
  data: T[];
  columns: ColumnDef<T>[];
  withPagination?: boolean;
  withGlobalFilter?: boolean;
  renderExpanded?: (row: Row<T>) => ReactNode;
}

export default function DataTable<T extends RowData>(props: Props<T>) {
  const {
    columns,
    data,
    withPagination = true,
    withGlobalFilter = true,
    renderExpanded = () => null,
  } = props;
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="attack-table">
      <div className="offset-lg-2 col-lg-8 col-md-12">
        <div className="grid gap-4 lg:grid-cols-2">
          {withGlobalFilter && (
            <div className="flex flex-col justify-start gap-4 lg:flex-row lg:items-center">
              <div className="search-label">
                <DataTableGlobalFilter
                  globalFilter={table.getState().globalFilter}
                  setGlobalFilter={table.setGlobalFilter}
                />
              </div>
            </div>
          )}

          {withPagination && (
            <div className="flex flex-col justify-end gap-4 lg:flex-row lg:items-center">
              <DataTablePagination
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                pageSize={table.getState().pagination.pageSize}
                gotoPage={table.setPageIndex}
                setPageSize={table.setPageSize}
                pageIndex={table.getState().pagination.pageIndex}
              />
            </div>
          )}
        </div>

        {table.getRowModel().rows.length === 0 ? (
          <p>No data found.</p>
        ) : (
          <div
            className={`my-4 rounded border border-white ${
              withPagination ? "" : "max-h-96 overflow-y-scroll"
            }`}
          >
            <table className="table table-bordered">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ||
                        header.column.columnDef.header === "Link" ? null : (
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <FontAwesomeIcon
                                  icon={faAngleUp}
                                  width={16}
                                  height={16}
                                />
                              ),
                              desc: (
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  width={16}
                                  height={16}
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          <div
                            className={`${
                              cell.column.columnDef.header === "Date"
                                ? "event-date"
                                : ""
                            }`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex flex-row justify-content-center">
          {withPagination && (
            <div className="flex flex-col justify-end gap-4 lg:flex-row lg:items-center">
              <DataTablePagination
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                pageSize={table.getState().pagination.pageSize}
                gotoPage={table.setPageIndex}
                setPageSize={table.setPageSize}
                pageIndex={table.getState().pagination.pageIndex}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
