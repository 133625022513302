const types: Record<string, string> = {
  "medical specialist": "medical specialists",
  hospital: "hospitals",
  "care facility": "care facilities",
  clinic: "clinics",
  "healthcare network": "healthcare networks",
  "national health system": "national health systems",
  "laboratories and diagnostics center": "laboratories and diagnostics centers",
  "mental health and substance abuse facility":
    "mental health and substance abuse facilities",
  "ambulance services": "ambulance services",
};

export function getOrganizationType(key: string) {
  return types[key.toLowerCase()] ?? key;
}
