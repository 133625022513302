import React, { ReactElement } from "react";
import * as d3 from "d3";
import { feature } from "topojson-client";
import countryTopoJSON from "../../utils/data/countries/countries-110m-topo.json";
import colorDictionary from "../../utils/colors/ColorDictionary";

import "./BubbleMap.css";
import { Tooltip } from "react-svg-tooltip";
import { getWidth, handleResize } from "../../utils/window";
import { Attack } from "../types";
import { GeometryCollection, Topology } from "topojson-specification";
import { PieArcDatum } from "d3-shape";
import {
  returnAttackCategories,
  returnAttackTypes,
  returnSubSectors,
} from "../../lib/filters/utils";
import { useZoomState } from "./hooks/zoom-state";

const pie = d3.pie<CitySingleDataEntry>().value((d) => d.value);

function getModalData(point: CityData) {
  const stats = getStatsByCategory(point);
  return (
    <>
      <b className="text-center">
        {point.name} - {point.values.all}{" "}
        {point.values.all > 1 ? "incidents" : "incident"}
      </b>
      <br />
      {stats.map((stat) => {
        return stat;
      })}
    </>
  );
}

function getStatsByCategory(point: CityData) {
  const stats = returnSortedCategory(point.type).reduce(
    (acc: Record<string, ReactElement>, category) => {
      const pointVal = Object.keys(point.values).filter(
        (key) => key === category
      )?.[0];
      if (category !== "all" && point.values[pointVal] > 0) {
        acc[category] = (
          <div key={`category-${category}`}>
            {category}: {point.values[category]}
            <br />
          </div>
        );
      }

      return acc;
    },
    {}
  );

  return Object.values(stats);
}

function returnSortedCategory(type: string) {
  return Object.keys(returnCategory(type)).sort((a, b) => b.length - a.length);
}

function returnCategory(type: string) {
  switch (type) {
    case "SubSector":
      return returnCategoryWithValues(returnSubSectors());
    case "AttackCategory":
      return returnCategoryWithValues(returnAttackCategories());
    case "AttackType":
      return returnCategoryWithValues(returnAttackTypes());
    default:
      console.log("Unknown value for type, using default which is SubSector");
      return returnCategoryWithValues(returnSubSectors());
  }
}

function returnCategoryWithValues(categories: string[]) {
  return categories.reduce(
    (acc: any, category: string) => {
      acc[category] = 0;
      return acc;
    },
    { all: 0 }
  );
}

function countByCategory(
  type: string,
  processedAttack: Record<string, number>,
  attack: Attack
) {
  switch (type) {
    case "SubSector":
      countSubSector(processedAttack, attack);
      break;
    case "AttackCategory":
      countAttackCategory(processedAttack, attack);
      break;
    case "AttackType":
      countAttackType(processedAttack, attack);
      break;
    default:
      console.log("Unknown value for type, using default which is SubSector");
      countSubSector(processedAttack, attack);
      break;
  }
}

function countSubSector(
  processedAttack: Record<string, number>,
  attack: Attack
) {
  for (let key in processedAttack) {
    if (
      key.toLowerCase() ===
      attack.involvedOrganization[0].primarySubSector.toLowerCase()
    ) {
      processedAttack[key]++;
    }
  }
}

function countAttackCategory(
  processedAttack: Record<string, number>,
  attack: Attack
) {
  for (let key in processedAttack) {
    if (key.toLowerCase() === attack.type.toLowerCase()) {
      processedAttack[key]++;
    }
  }
}

function countAttackType(
  processedAttack: Record<string, number>,
  attack: Attack
) {
  for (let key in processedAttack) {
    if (attack.subType.toLowerCase().startsWith(key.toLowerCase())) {
      processedAttack[key]++;
    }
  }
}

interface CityData {
  latitude: number;
  longitude: number;
  name: string;
  values: Record<string, number>;
  type: string;
  d: Array<PieArcDatum<CitySingleDataEntry>>;
  ref: any;
}

interface CitySingleDataEntry {
  value: number;
  category: string;
}

interface Props {
  attacks: Attack[];
  type: string;
}

function populateArcSegmentsAndReturnArray(
  processedAttacks: Record<string, CityData>
): Array<CityData> {
  return Object.values(processedAttacks).reduce(
    (acc: Array<CityData>, processedAttack: CityData) => {
      let pieValues: Array<CitySingleDataEntry> = [];
      for (let subType in processedAttack.values) {
        if (subType !== "all" && processedAttack.values[subType] !== 0) {
          pieValues.push({
            value: processedAttack.values[subType],
            category: subType,
          });
        }
      }
      processedAttack.d = pie(pieValues);
      acc.push(processedAttack);
      return acc;
    },
    []
  );
}

function getAttacks(props: Props) {
  const { attacks, type } = props;
  let data: Array<CityData> = [];
  let processedAttacks: Record<string, CityData> = {};
  if (attacks) {
    attacks.forEach((attack) => {
      let cityKey =
        attack.hasPrimaryLocation[0].name +
        ", " +
        attack.hasPrimaryLocation[0].country;
      processedAttacks[cityKey] = processedAttacks[cityKey] ?? {
        latitude: attack.hasPrimaryLocation[0].lat,
        longitude: attack.hasPrimaryLocation[0].lon,
        name: cityKey,
        values: returnCategory(type),
        type: type,
        ref: React.createRef(),
      };
      processedAttacks[cityKey].values.all++;

      countByCategory(type, processedAttacks[cityKey].values, attack);
    });

    data = populateArcSegmentsAndReturnArray(processedAttacks);
    data.sort((a, b) => b.values.all - a.values.all);
  }
  return { attacks: data };
}

function BubbleMap(props: Props) {
  // set the dimensions and margins of the graph
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const width = getWidth(margin);
  const height =
    width > 3000
      ? 1200 - margin.top - margin.bottom
      : 500 - margin.top - margin.bottom;

  const countries = feature(
    countryTopoJSON as unknown as Topology,
    countryTopoJSON.objects.countries as GeometryCollection
  );
  const [dimensions, setDimensions] = React.useState({ width, height });

  const containerRef = React.useRef(null);

  const [zoomState, zoomIn, zoomOut, mapRefCallback] = useZoomState(
    dimensions,
    width > 3000
      ? d3.zoomIdentity
          .translate(dimensions.width / 3.5, dimensions.height / 3.2)
          .scale(1.6)
      : d3.zoomIdentity.translate(110, 170).scale(0.7)
  );

  const projection = d3.geoMercator();
  const projectionX = (point: [number, number]) => {
    return projection(point)?.[0] || "";
  };

  const projectionY = (point: [number, number]) => {
    return projection(point)?.[1] || "";
  };

  const pathGenerator = d3.geoPath().projection(projection);

  const pointRadius = 12;

  const arc = d3
    .arc<PieArcDatum<CitySingleDataEntry>>()
    .innerRadius(0)
    .outerRadius(pointRadius);

  const scaleBubble =
    width > 3000
      ? d3.scaleLog().domain([1, 100]).range([0.8, 2.5]).nice()
      : d3.scaleLog().domain([1, 100]).range([0.4, 1.5]).nice();
  const scaleBubbleWithZoom =
    width > 3000
      ? (value: number) => {
          return zoomState && zoomState.k > 1.7
            ? (scaleBubble(value) / zoomState.k) * 2
            : scaleBubble(value);
        }
      : (value: number) => {
          return zoomState && zoomState.k > 1
            ? scaleBubble(value) / zoomState.k
            : scaleBubble(value);
        };

  React.useEffect(() => {
    const handler = () => handleResize(dimensions, setDimensions, margin);
    window.addEventListener("resize", handler);

    return function cleanupResizeListener() {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const state = getAttacks(props);

  return (
    <div ref={containerRef} className="map-container">
      <svg
        className="bubblemap-svg"
        width={dimensions.width + margin.left + margin.right}
        height={dimensions.height + margin.top + margin.bottom}
      >
        <defs>
          <clipPath id="clipPathBubble">
            <rect
              width={dimensions.width}
              height={dimensions.height}
              x={0}
              y={0}
            />
          </clipPath>
        </defs>
        <g ref={mapRefCallback as any}>
          <rect width="100%" height="100%" className="map-background" />
          <g className="main-map-container" transform={zoomState.toString()}>
            <g id="countries-container">
              {countries &&
                countries.features.map((d, index) => (
                  <path
                    key={`countries-${index}`}
                    className="country"
                    d={pathGenerator(d) ?? ""}
                  />
                ))}
            </g>
            <g id="datapoints-container">
              {state.attacks &&
                state.attacks.map((d, index) => (
                  <g key={`datapoint-${index}`}>
                    <g
                      transform={`translate(${projectionX([
                        d.longitude,
                        d.latitude,
                      ])},${projectionY([
                        d.longitude,
                        d.latitude,
                      ])}) scale(${scaleBubbleWithZoom(d.values.all)})`}
                      ref={d.ref}
                    >
                      {d.d.map((arcSegment, nestedIndex) => (
                        <path
                          key={`datapoint-part-${index}-${nestedIndex}`}
                          className="pie-pieces"
                          fill={
                            colorDictionary[props.type][
                              arcSegment.data.category.toLowerCase()
                            ] as string
                          }
                          d={arc(arcSegment) ?? ""}
                        />
                      ))}
                    </g>
                    <Tooltip triggerRef={d.ref}>
                      <foreignObject
                        className="map-tooltip-container"
                        textAnchor="start"
                        width={1}
                        height={1}
                      >
                        <div className="map-tooltip">{getModalData(d)}</div>
                      </foreignObject>
                    </Tooltip>
                  </g>
                ))}
            </g>
          </g>
        </g>

        <g>
          <foreignObject
            className="category-labels"
            textAnchor="start"
            fontSize="18px"
            fontWeight="bold"
            width={1}
            height={1}
            x={10}
            y={10}
          >
            {returnSortedCategory(props.type).map((category, index) => (
              <div key={`category-${index}`}>
                {category !== "all" && (
                  <div
                    className="category-label"
                    style={{
                      color: `${
                        colorDictionary[props.type][category.toLowerCase()]
                      }`,
                      borderColor: `${
                        colorDictionary[props.type][category.toLowerCase()]
                      }`,
                    }}
                  >
                    {category}
                  </div>
                )}
              </div>
            ))}
          </foreignObject>
        </g>
        <g>
          <foreignObject
            className="zoom-labels"
            textAnchor="start"
            width={32}
            height={70}
            x={dimensions.width - 45}
            y={10}
          >
            <div
              className="rounded-full text-4xl text-center py-0 w-8 h-8 bg-white hover:text-cpi text-cpi border border-cpi"
              onClick={zoomIn}
            >
              <div className="-mt-1.5">+</div>
            </div>
            <div
              className="rounded-full text-4xl text-center mt-1.5 py-0 w-8 h-8 bg-white hover:text-cpi text-cpi border border-cpi"
              onClick={zoomOut}
            >
              <div className="-mt-2">-</div>
            </div>
          </foreignObject>
        </g>
      </svg>
    </div>
  );
}

export default BubbleMap;
