import React from "react";
import colorDictionary from "../../utils/colors/ColorDictionary";
import { getWidthWithMin, handleResizeWithMin } from "../../utils/window";
import * as d3 from "d3";
import "./NetworkGraphChart.css";
import { voronoiTreemap } from "d3-voronoi-treemap";
import { Attack } from "../types";
import { HierarchyNode, HierarchyPolygonNode } from "d3-hierarchy";

import "./ImpactAcrossIncidents.css";
import ChartTitle from "../../lib/diagrams/ChartTitle";
import Chart from "../../lib/diagrams/Chart";
import { Tooltip } from "react-svg-tooltip";
import { returnSubSectors } from "../../lib/filters/utils";

const sep = ";";

interface Props {
  attacks: Attack[];
}

export default function ImpactAcrossIncidents(props: Props) {
  // set the dimensions and margins of the graph
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const width = getWidthWithMin(margin, 900);
  const height =
    width > 3000
      ? 1200 - margin.top - margin.bottom
      : 400 - margin.top - margin.bottom;
  const containerRef = React.useRef(null);
  const svgRef = React.useRef(null);

  const voronoiVisual: any = voronoiTreemap();

  const [dimensions, setDimensions] = React.useState({ width, height });

  React.useEffect(() => {
    const handler = () =>
      handleResizeWithMin(dimensions, setDimensions, margin, 900);
    window.addEventListener("resize", handler);

    return function cleanupResizeListener() {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const subSectors = getSubSectors(props.attacks);
  const termsBySubSector = getTermsBySubSector(props.attacks, subSectors);
  const series = getSeries(termsBySubSector);
  populateSeriesWeight(series);
  const seriesWithParent: NodeData = {
    name: "root",
    weight: 0,
    weightAll: 0,
    value: 0,
    ref: React.createRef(),
    color: undefined,
    children: series,
  };

  const fontScale = d3.scaleLinear().range([8, 13]);
  fontScale.domain([1, series?.[0]?.children?.[0]?.weight]);

  let hierarchyPolygon: HierarchyPolygonNode<NodeData> | undefined;

  try {
    const hierarchy: HierarchyNode<NodeData> = d3
      .hierarchy<NodeData>(seriesWithParent)
      .sum(function (d) {
        return d.weightAll;
      });
    hierarchyPolygon = hierarchy as unknown as HierarchyPolygonNode<NodeData>;
    voronoiVisual.clip([
      [0, 0],
      [0, height],
      [width, height],
      [width, 0],
    ]);

    if (seriesWithParent.children.length > 0) {
      voronoiVisual(hierarchy);
    }
  } catch (exception) {
    hierarchyPolygon = undefined;
    console.error(exception);
  }

  if (!hierarchyPolygon) {
    return <></>;
  }

  return (
    <Chart>
      <ChartTitle
        title="Most prominent types of data breached (number of incidents per
      sub-sector)"
      />
      <div ref={containerRef} className="voronoi-treemap-container">
        <svg
          ref={svgRef}
          className="voronoi-treemap-svg"
          width={dimensions.width + margin.left + margin.right}
          height={dimensions.height + margin.top + margin.bottom}
        >
          <defs>
            <clipPath id="clipPathVoronoi">
              <rect
                width={dimensions.width}
                height={dimensions.height}
                x={0}
                y={0}
              />
            </clipPath>
          </defs>

          <g>
            {hierarchyPolygon.leaves().length > 0 &&
              hierarchyPolygon.leaves()[0].data.name !== "root" &&
              hierarchyPolygon.leaves().map((node, index) => (
                <g key={`cell-${index}`}>
                  <g ref={node.data.ref}>
                    <g className="cell">
                      <path
                        d={"M" + node.polygon.join(",") + "z"}
                        style={{
                          fill:
                            node.parent != null ? node.parent.data.color : "",
                        }}
                      />
                    </g>
                    <g
                      className={`label ${isYellow(node)}`}
                      transform={`translate(${node.polygon?.site?.x}, ${node.polygon?.site?.y})`}
                      style={{ fontSize: fontScale(node.data.weightAll) }}
                    >
                      <text className="name">{displayValue(node.data)}</text>
                      <text className="value">{node.data.value}</text>
                    </g>
                    <g className="hoverer">
                      <path d={"M" + node.polygon.join(",") + "z"} />
                    </g>
                  </g>
                  <Tooltip triggerRef={node.data.ref}>
                    <foreignObject
                      className="voronoi-graph-tooltip-container"
                      textAnchor="start"
                      width={1}
                      height={1}
                    >
                      <div
                        className="voronoi-graph-tooltip-container-inner"
                        style={{
                          borderColor:
                            node.parent != null ? node.parent.data.color : "",
                        }}
                      >
                        {getInnerNodeModalData(node.data)}
                      </div>
                    </foreignObject>
                  </Tooltip>
                </g>
              ))}
          </g>
        </svg>
        <div className="voronoi-graph-categories">
          <div className="flex justify-center gap-4">
            {returnSubSectors().map((subSector, index) => (
              <div
                className="flex items-center gap-2"
                key={`category-${index}`}
              >
                <div
                  className="rounded-full w-4 h-4"
                  style={{
                    minWidth: "1rem",
                    backgroundColor:
                      subSector.toLowerCase() === "other"
                        ? "#919191"
                        : (colorDictionary.SubSector[
                            subSector.toLowerCase()
                          ] as string),
                  }}
                ></div>
                {subSector}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Chart>
  );
}

function isYellow(node: HierarchyPolygonNode<NodeData>) {
  return node.parent != null &&
    node.parent.data.name === "Medical Manufacturing & Development"
    ? "yellow"
    : "";
}

function displayValue(data: NodeData) {
  return data.name.length > data.weight
    ? data.name.substring(0, data.weight).concat("...")
    : data.name;
}

function getInnerNodeModalData(data: NodeData) {
  return (
    <>
      <b className="text-center">{data.name}</b>
      <br />
      <b className="text-center">{data.value}</b>
    </>
  );
}

interface TermsBySector {
  [key: string]: string[];
}

interface NodeData {
  name: string;
  weight: number;
  weightAll: number;
  value: number;
  color: string | undefined;
  ref: any;
  children: Array<NodeData>;
}

function getSubSectors(items: Attack[]) {
  return Array.from(
    new Set(
      items.map(
        ({ involvedOrganization }) => involvedOrganization[0].primarySubSector
      )
    )
  );
}

function getTermsBySubSector(items: Attack[], subSectors: string[]) {
  return subSectors.reduce((acc: TermsBySector, subSector) => {
    const itemsBySubSector = items.filter(
      ({ involvedOrganization }) =>
        involvedOrganization[0].primarySubSector === subSector
    );

    if (!acc[subSector]) {
      acc[subSector] = [];
    }
    acc[subSector].push(
      ...itemsBySubSector
        .flatMap(({ consequences }) =>
          consequences[0].otherInfo.split(sep).map((t) => t.trim())
        )
        .filter((t) => !!t)
    );
    return acc;
  }, {});
}

function populateSeriesWeight(series: Array<NodeData>) {
  let max = 1;
  let min = Number.MAX_SAFE_INTEGER;
  let sumAll = 0;
  series.forEach((serie) => {
    let sum = 0;
    serie.children.forEach((node) => {
      sum += node.value;
      sumAll += node.value;
      max = node.value > max ? node.value : max;
      min = node.value < min ? node.value : min;
    });
    serie.children.forEach((node) => {
      const percentage = (node.value / sum) * 100;
      node.weight =
        percentage < 1 ? Math.ceil(percentage) : Math.round(percentage);
    });
  });

  series.forEach((serie) => {
    serie.children.forEach((node) => {
      node.weightAll = Math.ceil((node.value / sumAll) * 100);
    });
  });
}

function getSeries(termsBySubSector: TermsBySector) {
  return Object.entries(termsBySubSector).reduce(
    (series: Array<NodeData>, [key, allTerms]) => {
      const termsCount = allTerms.reduce(
        (acc: Record<string, number>, term) => {
          const lTerm = term.toLowerCase();
          acc[lTerm] = !acc[lTerm] ? 1 : acc[lTerm] + 1;
          return acc;
        },
        {}
      );

      if (Object.entries(termsCount).length === 0) {
        return series;
      }

      const singleSeries: NodeData = {
        name: key,
        weight: 0,
        weightAll: 0,
        value: 0,
        ref: React.createRef(),
        color:
          key.toLowerCase() === "other"
            ? "#919191"
            : (colorDictionary.SubSector[key.toLowerCase()] as string),
        children: Object.entries(termsCount).reduce(
          (acc: NodeData["children"], [name, value]) => [
            ...(acc as any),
            {
              name,
              value,
              ref: React.createRef(),
            },
          ],
          []
        ),
      };

      singleSeries.children.sort((a, b) => b.value - a.value);
      singleSeries.children = singleSeries.children.splice(0, 15);

      return [...series, singleSeries];
    },
    []
  );
}
