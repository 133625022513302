import React, { PropsWithChildren } from "react";
import { useInView } from "react-intersection-observer";
import { overrideClassNames } from "./utils";

interface Props {
  className?: string;
}

const defaultClassNames = "opacity-0 transition-opacity duration-500";

export default function Fade(props: PropsWithChildren<Props>) {
  const { ref, inView } = useInView({});
  return (
    <div
      className={overrideClassNames(defaultClassNames, props.className)}
      style={inView ? { opacity: 1 } : {}}
      ref={ref}
    >
      {props.children}
    </div>
  );
}
