import React from "react";
import { useLocation } from "react-router-dom";

export default function Analytics() {
  const location = useLocation();

  React.useEffect(() => {
    const info = {
      page_location: window.location.origin + window.location.pathname,
      page_path: window.location.pathname,
      page_title: document.title,
    };
    (window as any).gtag("event", "page_view", info);
    if (info.page_path === "/act") {
      (window as any).gtag("event", "conversion", {
        send_to: "AW-625189749/gY79CJT68oADEPXGjqoC",
      });
    }
  }, [location]);

  return null;
}
