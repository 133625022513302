import React from "react";
import { Link } from "react-router-dom";
import Page from "../../lib/Page";
import Section from "../../lib/Section";

export default function NotFound() {
  return (
    <Page
      title="Page not found."
      intro={
        <>
          <p>
            The Cyber Incident Tracer #HEALTH (CIT) is a data platform about
            cyberattacks which disrupt the delivery of healthcare, compromise
            sensitive healthcare-related data, and have an impact on patients,
            healthcare professionals, facilities and organizations. The platform
            provides data-driven understanding of the impact of cyberattacks on
            healthcare.
          </p>
        </>
      }
    >
      <Section title="Find your way">
        <ul className="list-disc">
          <li>
            Go through the{" "}
            <Link className="underline text-cpi" to="/">
              home page
            </Link>
          </li>
          <li>
            Explore the{" "}
            <Link className="underline text-cpi" to="/explore">
              data
            </Link>
          </li>
          <li>
            <Link className="underline text-cpi" to="act">
              Help us
            </Link>
          </li>
        </ul>
      </Section>
    </Page>
  );
}
