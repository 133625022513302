import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import * as d3 from "d3";
import ChartTitle from "../../lib/diagrams/ChartTitle";
import Chart from "../../lib/diagrams/Chart";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/item-series")(Highcharts);

const scaleSize = d3.scaleLinear().domain([0, 475]).range([30, 190]);

function returnChartOptions(data, size) {
  return {
    chart: {
      type: "item",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    legend: {
      enabled: true,
      layout: "vertical",
      //align: "right",
      verticalAlign: "bottom",
      itemMarginTop: 0,
      itemMarginBottom: 0,
    },

    series: [
      {
        name: "",
        keys: ["name", "y", "color", "label"],
        rows: 6,
        data: data,
        dataLabels: {
          enabled: false,
        },

        // Circular options
        center: ["50%", "95%"],
        size: size,
        startAngle: -100,
        endAngle: 100,
      },
    ],

    exporting: {
      enabled: false,
    },
  };
}

function returnRecordsBreachedCategories() {
  return [
    {
      name: "Unknown",
      color: "#919191",
    },
    {
      name: "< 1000",
      min: 0,
      max: 999,
      color: "#E5EBF4",
    },
    {
      name: "1000 to 9999",
      min: 1000,
      max: 9999,
      color: "#B2C5E0",
    },
    {
      name: "10000 to 49999",
      min: 10000,
      max: 49999,
      color: "#7F9ECB",
    },
    {
      name: "50000 to 99999",
      min: 50000,
      max: 99999,
      color: "#4C78B6",
    },
    {
      name: ">= 100000",
      min: 100000,
      max: Number.MAX_SAFE_INTEGER,
      color: "#003E97",
    },
  ];
}

function returnOperationalImpactDurationCategories() {
  return [
    {
      name: "Unknown",
      color: "#919191",
    },
    {
      name: "<= 1 day",
      min: 0,
      max: 1.0001,
      color: "#E5EBF4",
    },
    {
      name: "a week or less (2-7 days)",
      min: 2,
      max: 7,
      color: "#B2C5E0",
    },
    {
      name: "a week to a fortnight (8-14 days)",
      min: 8,
      max: 14,
      color: "#7F9ECB",
    },
    {
      name: "between 2 weeks a month (15-30 days)",
      min: 15,
      max: 30,
      color: "#4C78B6",
    },
    {
      name: "more than a month (>= 31 days)",
      min: 31,
      max: Number.MAX_SAFE_INTEGER,
      color: "#003E97",
    },
  ];
}

function getSize(data) {
  return scaleSize(data.length) + "%";
}

function averageForRecords(average) {
  let length = (Math.log(average) * Math.LOG10E + 1) | 0;
  let roundingPrecision = length >= 6 ? 3 : 2;
  return (
    Math.round(average / Math.pow(10, length - roundingPrecision)) *
    Math.pow(10, length - roundingPrecision)
  );
}

function textForTotalByType(type) {
  switch (type) {
    case "RecordsBreached":
      return "total records breached";
    case "OperationalImpactDuration":
      return "total days of disruption";
    default:
      return "total records breached";
  }
}

function textForAverageByType(type) {
  switch (type) {
    case "RecordsBreached":
      return "average records per incident";
    case "OperationalImpactDuration":
      return "days on average";
    default:
      return "average records per incident";
  }
}

function textForMaximumByType(type) {
  switch (type) {
    case "RecordsBreached":
      return "maximum records for one incident";
    case "OperationalImpactDuration":
      return "days maximum for one incident";
    default:
      return "maximum records for one incident";
  }
}

function roundAverageByType(average, type) {
  switch (type) {
    case "RecordsBreached":
      return averageForRecords(average);
    case "OperationalImpactDuration":
      return Math.round(average * 10) / 10;
    default:
      return averageForRecords(average);
  }
}

function countCategory(attack, type, categories) {
  let records;
  switch (type) {
    case "RecordsBreached":
      records = attack.consequences[0].nbRecords;
      break;
    case "OperationalImpactDuration":
      records = attack.consequences[0].opImpactDuration;
      break;
    default:
      records = attack.consequences[0].nbRecords;
      console.log(
        "Unknown value for type, using default which is RecordsBreached"
      );
  }

  if (!records) {
    categories[0].y++;
    return 0;
  }
  for (const [i, category] of categories.entries()) {
    if (i === 0) {
      continue;
    }
    if (records < category.max) {
      category.y++;
      return parseFloat(records);
    }
  }

  return 0;
}

function initData(type) {
  let data;
  switch (type) {
    case "RecordsBreached":
      data = returnRecordsBreachedCategories();
      break;
    case "OperationalImpactDuration":
      data = returnOperationalImpactDurationCategories();
      break;
    default:
      data = returnRecordsBreachedCategories();
      console.log(
        "Unknown value for type, using default which is RecordsBreached"
      );
  }

  for (const category of data) {
    category.y = category.y ?? 0;
  }

  return data;
}

class ParliamentChart extends Component {
  constructor(props) {
    super(props);

    this.updateMaxElements = true;
    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: returnChartOptions(this.categories, "80%"),
      average: "0",
      max: "0",
      generalCount: "0",
    };
  }

  updateMaxElementsOnFirstRefresh(attacks) {
    if (this.updateMaxElements) {
      this.updateMaxElements = false;
      if (attacks.length > 250) {
        scaleSize.domain([0, attacks.length]);
      }
    }
  }

  refreshData() {
    const { attacks, type } = this.props;
    let data = initData(type);
    let size = "80%";
    let average = 0;
    let generalCount = 0;
    let maxCount = 0;

    if (attacks) {
      this.updateMaxElementsOnFirstRefresh(attacks);

      attacks.forEach((attack) => {
        let currentCount = countCategory(attack, type, data);
        maxCount = maxCount > currentCount ? maxCount : currentCount;
        generalCount += currentCount;
      });

      average =
        generalCount > 0 ? generalCount / (attacks.length - data[0].y) : 0;
      average = roundAverageByType(average, type);

      size = getSize(attacks);
    }

    this.setState({
      chartOptions: returnChartOptions(data, size),
      average: average.toLocaleString(),
      max: maxCount.toLocaleString(),
      generalCount: generalCount.toLocaleString(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      this.props.attacks &&
      prevProps.attacks !== this.props.attacks
    ) {
      this.refreshData();
    }
  }

  render() {
    const { chartOptions, average, generalCount, max } = this.state;
    const { type } = this.props;

    return (
      <Chart className="nested-parliament">
        <ChartTitle title={this.props.title} />
        <div className="titles">
          {average !== "0" && (
            <div>
              <b>{average}</b> {textForAverageByType(type)}
            </div>
          )}
          {max !== "0" && (
            <div>
              <b>{max}</b> {textForMaximumByType(type)}
            </div>
          )}
          {generalCount !== "0" && (
            <div>
              <b>{generalCount}</b> {textForTotalByType(type)}
            </div>
          )}
        </div>

        <div className="relative">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </Chart>
    );
  }
}

export default ParliamentChart;
