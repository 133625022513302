import React from "react";
import BubbleMap from "../d3/BubbleMap";
import SunBurstChart from "../highcharts/SunBurstChart";
import ChartTitle, {
  TitleWithButtonsType,
} from "../../lib/diagrams/ChartTitle";
import { Attack } from "../types";
import Chart from "../../lib/diagrams/Chart";

interface Props {
  attacks: Attack[];
}

export default function IncidentsInSpace(props: Props) {
  const [show, setShow] = React.useState(TitleWithButtonsType.Left);

  return (
    <>
      <Chart>
        <ChartTitle
          title="Incidents in space (by city)"
          buttonLeft="By Sub-Sector"
          buttonRight="By Incident Type"
          onClick={setShow}
        />

        {show === TitleWithButtonsType.Left && (
          <BubbleMap attacks={props.attacks} type="SubSector" />
        )}
        {show === TitleWithButtonsType.Right && (
          <BubbleMap attacks={props.attacks} type="AttackType" />
        )}
      </Chart>
      <Chart>
        <ChartTitle title="Proportion of incidents by geography (by region, sub-region and country)" />
        <SunBurstChart attacks={props.attacks} />
      </Chart>
    </>
  );
}
