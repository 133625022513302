import Moment from "moment";

function getNumber(val: number | string) {
  let value = val;
  try {
    const temp = parseInt(val as string, 10);
    if (!isNaN(temp)) {
      return temp;
    }
  } catch {}

  return val;
}

export function getFormatedDate(timestamp: string | undefined) {
  const date = Moment(timestamp);
  return date.format("D-MMM-YYYY");
}

export function formatNumber(val: number | string) {
  const value = getNumber(val);
  if (typeof value === "number") {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    return value;
  }
}

export function formatDays(val: number | string) {
  const value = getNumber(val);
  if (typeof value === "number") {
    return `${value} days`;
  } else {
    return value;
  }
}

export default function formatMillion(val: number | undefined) {
  if (val !== 0 && !val) {
    return val;
  }

  const valString = val.toFixed(0);
  return parseFloat(
    (parseFloat(valString.substring(0, valString.length - 5)) / 10).toFixed(1)
  );
}
