import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  previousPage: VoidFunction;
  canPreviousPage: boolean;
  nextPage: VoidFunction;
  canNextPage: boolean;
  pageOptions: number[];
  pageSize: number;
  gotoPage: (n: number) => void;
  setPageSize: (n: number) => void;
  pageIndex: number;
}

const pageSizeOptions = [10, 25, 50];

export default function DataTablePagination(props: Props) {
  const {
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageSize,
    pageOptions,
    gotoPage,
    setPageSize,
    pageIndex,
  } = props;
  return (
    <div className="flex justify-between gap-2">
      <div className="flex items-center gap-2">
        <button
          onClick={() => gotoPage(0)}
          className={`${!canPreviousPage ? "cursor-default" : ""}`}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            color={canPreviousPage ? "gray" : "white"}
            icon={faAnglesLeft}
            width={24}
            height={24}
          />
        </button>
        <button
          onClick={() => previousPage()}
          className={`${!canPreviousPage ? "cursor-default" : ""}`}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            color={canPreviousPage ? "gray" : "white"}
            icon={faAngleLeft}
            width={24}
            height={24}
          />
        </button>
        <span>
          {pageIndex + 1} / {pageOptions.length}
        </span>
        <button
          onClick={() => nextPage()}
          className={`${!canNextPage ? "cursor-default" : ""}`}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon
            color={canNextPage ? "gray" : "white"}
            icon={faAngleRight}
            width={24}
            height={24}
          />
        </button>
        <button
          onClick={() => gotoPage(pageOptions.length - 1)}
          className={`${!canNextPage ? "cursor-default" : ""}`}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon
            color={canNextPage ? "gray" : "white"}
            icon={faAnglesRight}
            width={24}
            height={24}
          />
        </button>
      </div>
      <div>
        <select
          className="form-select text-dark"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
