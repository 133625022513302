import { Attack } from "../types";
import { AttackFilterAtom, FilterValue } from "../../atoms";

function filterByValue(
  attacks: Attack[],
  attackFilter: FilterValue[],
  filter: (f: FilterValue, attack: Attack) => boolean
) {
  if (attackFilter && attackFilter.length > 0) {
    return attackFilter.flatMap((attackCategory) =>
      attacks.filter((a) => filter(attackCategory, a))
    );
  } else {
    return attacks;
  }
}

function filterByDate(
  attacks: Attack[],
  attackFilter: string,
  filter: (f: string, attack: Attack) => boolean
) {
  if (attackFilter) {
    return attacks.filter((a) => filter(attackFilter, a));
  } else {
    return attacks;
  }
}

export function filterAttacks(items: Attack[], attackFilter: AttackFilterAtom) {
  const filtersByValue: Record<
    string,
    (filter: FilterValue, attack: Attack) => boolean
  > = {
    selectedAttackCategories: (filter, attack) =>
      attack.type.toLowerCase() === filter.value,
    selectedAttackTypes: (filter, attack) =>
      attack.subType.toLowerCase() === filter.value,
    selectedRansomwareOperator: (filter, attack) =>
      attack.involvedThreatActor[0]
        ? attack.involvedThreatActor[0].name.toLowerCase() === filter.value
        : false,
    selectedSubSectors: (filter, attack) =>
      attack.involvedOrganization[0].primarySubSector.toLowerCase() ===
      filter.value,
    selectedOrganizationTypes: (filter, attack) =>
      attack.involvedOrganization[0].organizationType.toLowerCase() ===
      filter.value,
    selectedRegions: (filter, attack) =>
      attack.hasPrimaryLocation[0].region.toLowerCase() === filter.value,
    selectedCountries: (filter, attack) =>
      attack.hasPrimaryLocation[0].countryAbbreviation.toLowerCase() ===
      filter?.shortValue?.toLowerCase(),
  };
  const filtersByDate: Record<
    string,
    (filter: string, attack: Attack) => boolean
  > = {
    fromDate: (filter, attack) =>
      new Date(attack.eventDateFrom).valueOf() >= new Date(filter).valueOf(),
    toDate: (filter, attack) =>
      new Date(attack.eventDateFrom).valueOf() <= new Date(filter).valueOf(),
  };

  const filters: (keyof AttackFilterAtom)[] = [
    "selectedAttackCategories",
    "selectedAttackTypes",
    "selectedRansomwareOperator",
    "selectedSubSectors",
    "selectedOrganizationTypes",
    "selectedRegions",
    "selectedCountries",
    "fromDate",
    "toDate",
  ];

  // Here, we start from `items` (initial `acc`) and run all `filters`, passing the result into the next one.
  const filtered = filters.reduce(
    (acc, filterName) => {
      if (["fromDate", "toDate"].includes(filterName)) {
        return filterByDate(
          acc,
          attackFilter[filterName] as string,
          filtersByDate[filterName]
        );
      }

      return filterByValue(
        acc,
        attackFilter[filterName] as FilterValue[],
        filtersByValue[filterName]
      );
    },
    [...items]
  );

  filtered.sort(
    (a, b) =>
      new Date(b.eventDateFrom).valueOf() - new Date(a.eventDateFrom).valueOf()
  );

  return filtered;
}
