import { AttacksResponse } from "../diagrams/types";

export interface HomeData {
  // General
  attacks: number;
  lastPublished: string;
  countries: number;
  impactRecords: number;
  impactOffline: number;
  impactLeaked: number;

  // Patient Care Services
  attacksPatientCareServices: number;
  impactDisruptions: number;
  impactDivertions: number;
  impactPostponed: number;

  // Extra
  disruptionsValues: number[];
  organizationTypes: string[];
}

type RequestType = "home" | "explore";

async function getData<T>(type: RequestType): Promise<T> {
  const res = await fetch(`/data/${type}.json`, {
    mode: "cors",
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return res.json();
}

export async function getHomeData(): Promise<HomeData> {
  return getData("home");
}

export async function getExploreData(): Promise<AttacksResponse[]> {
  console.log("In getExploreData");
  return getData("explore");
}
