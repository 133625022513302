import React, { PropsWithChildren } from "react";
import LinkButton from "../../lib/LinkButton";
import { Parallax } from "react-parallax";
import DownArrow from "../../lib/DownArrow";

function Container(props: PropsWithChildren<{}>) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const onResize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  if (width > 768) {
    return (
      <Parallax
        bgImage="/images/background-title.png"
        bgImageStyle={{ height: "auto", top: "-3rem" }}
      >
        {props.children}
      </Parallax>
    );
  } else {
    return <>{props.children}</>;
  }
}

export default function Chapter1() {
  return (
    <Container>
      <section className="md:mb-8 py-8 px-4 md:pt-12 md:pb-4 home-section text-center text-cpi">
        <img
          className="block w-32 md:w-44 mb-8 md:mb-12"
          src="/images/CyberPeace-Institute-logo-new-green.svg"
          alt="CyberPeace Institute logo"
        />

        <h1 className="mb-8 md:mb-12 text-4xl md:text-6xl font-extralight">
          Cyber
          <br />
          Incident Tracer
          <br />
          #HEALTH
        </h1>

        <p className="mt-8 mb-8 md:mb-12 md:mb-20">
          This platform bridges the information gap about cyberattacks on the
          healthcare sector and their impact on people.
        </p>

        <div className="flex justify-center gap-8">
          <LinkButton
            className="w-72 px-4 md:px-16 py-3 md:py-4 text-cpi hover:bg-sec hover:border-sec"
            to="/explore"
          >
            Access Data
          </LinkButton>
          <LinkButton
            className="w-72 px-4 md:px-16 py-3 md:py-4 font-extrabold bg-cpi text-white hover:bg-sec hover:border-sec"
            to="/act"
          >
            Act Now
          </LinkButton>
        </div>

        <DownArrow className="mt-8 md:mt-16 mb-8 animate-bounce" />
      </section>
    </Container>
  );
}
