import { Attack } from "../types";
import DataTable from "./DataTable";
import React, { ReactNode } from "react";
import { ColumnDef, Row, RowData } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

interface Props<T extends RowData> {
  data: Attack[];
  handleShow: (key: string) => void;
}

export default function AttackTable<T extends RowData>(props: Props<T>) {
  const attackTableColumns: ColumnDef<Attack>[] = [
    {
      header: "Date",
      accessorFn: (object: Attack) => object.eventDateFrom.split("T")[0],
    },
    {
      header: "Incident Type",
      accessorFn: (object: Attack) => object.subType,
    },
    {
      header: "Location",
      cell: ({ row }) => (
        <>
          <div>
            <span
              className="country-toolbox"
              data-for="country-toolbox"
              data-tip={row.original.hasPrimaryLocation[0].country}
            >
              <img
                alt={`Flag for ${row.original.hasPrimaryLocation[0].country}`}
                className="round-flag"
                src={`./data/countries/img/${row.original.hasPrimaryLocation[0].countryAbbreviation.toLowerCase()}.svg`}
              />
            </span>
            <ReactTooltip
              id="country-toolbox"
              className="country-toolbox-class"
              delayHide={500}
              effect="solid"
            />
          </div>
        </>
      ),
    },
    {
      header: "Organization Type",
      accessorFn: (object: Attack) =>
        object.involvedOrganization[0].organizationType,
    },
    {
      header: "Description",
      accessorFn: (object: Attack) => object.description,
    },
    {
      header: "Incident Certainty",
      cell: ({ row }) => (
        <div>
          <div
            className={`attack-certainty-box ${row.original.eventConfidence.toLowerCase()}`}
          >
            <span
              data-for="attack-certainty-toolbox"
              data-tip={row.original.eventConfidence}
            >
              <div></div>
            </span>
            <ReactTooltip
              id="attack-certainty-toolbox"
              className="attack-certainty-toolbox-class"
              delayHide={500}
              effect="solid"
            />
          </div>
        </div>
      ),
      accessorFn: (object: Attack) => object.eventConfidence,
    },
    {
      header: "Link",
      cell: ({ row }) => (
        <button
          className="btn btn-link btn-details"
          onClick={() => {
            props.handleShow(row.original._key);
          }}
        >
          <FontAwesomeIcon icon={faExternalLink} width={24} height={24} />
        </button>
      ),
    },
  ];

  return (
    <DataTable<Attack>
      data={props.data}
      columns={attackTableColumns}
      withGlobalFilter={true}
      withPagination={true}
    />
  );
}
