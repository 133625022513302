import React from "react";

export default function Maintenance() {
  return (
    <p className="bg-sec text-center text-cpi font-bold py-4">
      CIT is currently under maintenance. Some number and visuals may be
      missing.
    </p>
  );
}
