import React from "react";
import "./Navigation.css";
import NavMobile from "./NavMobile";
import NavDesktop from "./NavDesktop";

export default function Navigation() {
  return (
    <div className="nav-bar h-16 fixed top-0 w-screen bg-cpi text-xl">
      <NavMobile />
      <NavDesktop />
    </div>
  );
}
