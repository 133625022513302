import React, { useEffect } from "react";
import FilterForm from "../../FilterForm";
import Modal from "react-bootstrap/Modal";

import "./Visualizations.css";
import "../highcharts/Highcharts.css";
import { useRecoilState } from "recoil";
import { attackFilterAtom, incidentsResultsAtom } from "../../atoms";
import AttackPage from "../../routes/attack/AttackPage";
import TreeMap from "../highcharts/TreeMap";
import BubbleTimelineGrid from "../d3/BubbleTimelineGrid";
import FilterPeriods from "./FilterPeriods";
import ParliamentChart from "../highcharts/ParliamentChart";
import PercentageBarChart from "./PercentageBarChart";
import NetworkGraphChart from "../d3/NetworkGraphChart";
import ImpactAcrossIncidents from "../d3/ImpactAcrossIncidents";
import IncidentsInSpace from "./IncidentsInSpace";
import IncidentsOverTime from "./IncidentsOverTime";
import ChartTitle from "../../lib/diagrams/ChartTitle";
import Chart from "../../lib/diagrams/Chart";
import { Attack, YesNoUnknown } from "../types";
import { filterAttacks } from "./utils";
import Loading from "../../lib/Loading";
import { getExploreData } from "../../lib/api";
import AttackTable from "../Table/AttackTable";

function returnYesNoUnknown() {
  return {
    yes: 0,
    no: 0,
    unknown: 0,
  };
}

interface State {
  error?: Error;
  lastPublished: string;
  openElement?: Attack;
  isLoaded?: boolean;
  initialItems: Attack[];
  items: Attack[];
}

function sortResults(result: Attack[]) {
  result.forEach((entry) => {
    if (entry.subType.toLowerCase() === "ransomware (unconfirmed)") {
      entry.subType = "Ransomware";
    }
  });
  return result.sort(
    (a, b) =>
      new Date(b.eventDateFrom).valueOf() - new Date(a.eventDateFrom).valueOf()
  );
}

export default function Visualizations() {
  const [page] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [switches, setSwitches] = React.useState({ tabSwitch: true });
  const [staticStatistics, setStaticStatistics] = React.useState({
    uniqueOrganizations: 0,
    organizationMaxType: "other facilities",
    ransomAgainstHospitals: 0,
    otherMalwareAgainstHospitals: 0,
    unknownAgainstHospitals: 0,
    eventsPerCountry: 0,
    incidentsByPatientCareService: 0,
    averageOperationalImpactDuration: 0,
    allIncidents: 0,
    countries: 0,
  });

  const [dynamicStatistics, setDynamicStatistics] = React.useState({
    sumHcAppointmentsCancelled: returnYesNoUnknown(),
    sumHcPatientsRedirected: returnYesNoUnknown(),
    sumHcSystemsOffline: returnYesNoUnknown(),
    sumExposedOrLeaked: returnYesNoUnknown(),
    sumRansomPaid: returnYesNoUnknown(),
    sumPublishedOnline: returnYesNoUnknown(),
  });

  const goToTable = () => {
    setSwitches({ tabSwitch: false });
  };
  const goToVisuals = () => setSwitches({ tabSwitch: true });

  function sumYesNoUnknown(
    sumOfYesNoUnknown: YesNoUnknown,
    yesNoUnknown: string
  ) {
    switch (yesNoUnknown) {
      case "Yes":
        sumOfYesNoUnknown.yes++;
        break;
      case "No":
        sumOfYesNoUnknown.no++;
        break;
      case "Unknown":
        sumOfYesNoUnknown.unknown++;
        break;
      case "Not Applicable":
        break;
      default:
        sumOfYesNoUnknown.unknown++;
      /*console.log(
          "Unknown value '" + yesNoUnknown + "' for yesNoUnknown field"
        );*/
    }
  }

  function calculateDynamicStatistics(attacks: Attack[]) {
    let sumHcAppointmentsCancelled = returnYesNoUnknown();
    let sumHcPatientsRedirected = returnYesNoUnknown();
    let sumHcSystemsOffline = returnYesNoUnknown();
    let sumExposedOrLeaked = returnYesNoUnknown();

    let sumRansomPaid = returnYesNoUnknown();
    let sumPublishedOnline = returnYesNoUnknown();

    attacks.forEach((attack) => {
      const isPatientCareService =
        attack.involvedOrganization[0].primarySubSector.toLowerCase() ===
        "patient care services";

      let consequence = attack.consequences[0];

      if (isPatientCareService) {
        sumYesNoUnknown(
          sumHcAppointmentsCancelled,
          consequence.hcAppointmentsCancelled
        );
        sumYesNoUnknown(
          sumHcPatientsRedirected,
          consequence.hcPatientsRedirected
        );
      }

      sumYesNoUnknown(sumHcSystemsOffline, consequence.hcSystemsOffline);
      sumYesNoUnknown(sumExposedOrLeaked, consequence.exposedLeaked);

      if (
        attack.subType.toLowerCase() === "ransomware" ||
        attack.subType.toLowerCase() === "ransomware (unconfirmed)"
      ) {
        sumYesNoUnknown(sumRansomPaid, consequence.financial[0].ransomPaid);
        sumYesNoUnknown(sumPublishedOnline, consequence.dataDump);
      }
    });

    setDynamicStatistics({
      sumHcAppointmentsCancelled,
      sumHcPatientsRedirected,
      sumHcSystemsOffline,
      sumExposedOrLeaked,
      sumRansomPaid,
      sumPublishedOnline,
    });
  }

  function calculateStaticStatistics(attacks: Attack[]) {
    let orgCounter = 0;
    const orgsTracker: Record<string, string> = {};

    const eventPrimaryCountryTracker: Record<string, string> = {};
    let eventsPerCountry = 0;

    const orgType: Record<string, number> = {};
    let orgMaxPerType = 0;
    let orgMaxTypeName = "";

    let ransomAgainstHospitals = 0;
    let otherMalwareAgainstHospitals = 0;
    let unknownAgainstHospitals = 0;

    let incidentsByPatientCareService = 0;
    let sumOperationalImpactDuration = 0;

    attacks.forEach((attack) => {
      let isPatientCareService = false;

      if (!eventPrimaryCountryTracker[attack.eventPrimaryCountry]) {
        eventPrimaryCountryTracker[attack.eventPrimaryCountry] =
          attack.eventPrimaryCountry;
        eventsPerCountry++;
      }

      attack.involvedOrganization.forEach((org) => {
        if (!orgsTracker[org._key]) {
          orgsTracker[org._key] = org._key;

          // count organizations
          orgCounter++;

          // count attacks on specific healthcare organization type
          if (!orgType[org.organizationType]) {
            orgType[org.organizationType] = 0;
          }
          orgType[org.organizationType]++;

          // count ransomware on hospitals
          if (org.organizationType.toLowerCase() === "hospital") {
            if (attack.subType.toLowerCase() === "ransomware") {
              ransomAgainstHospitals++;
            } else if (attack.subType.toLowerCase() === "other malware") {
              otherMalwareAgainstHospitals++;
            } else if (attack.subType.toLowerCase() === "unknown") {
              unknownAgainstHospitals++;
            }
          }

          //
          if (org.primarySubSector.toLowerCase() === "patient care services") {
            isPatientCareService = true;
          }
        }

        if (isPatientCareService) {
          incidentsByPatientCareService++;
          let consequence = attack.consequences[0];

          if (consequence.opImpactDuration) {
            sumOperationalImpactDuration += parseFloat(
              consequence.opImpactDuration
            );
          }
        }
      });
    });

    for (let key in orgType) {
      if (key.toLowerCase() !== "hospital" && orgMaxPerType < orgType[key]) {
        orgMaxTypeName = key;
        orgMaxPerType = orgType[key];
      }
    }

    // Round the averageOperationalImpactDuration
    let averageOperationalImpactDuration =
      Math.round(
        (sumOperationalImpactDuration / incidentsByPatientCareService) * 100
      ) / 100;

    const countries = new Set(
      attacks
        .filter((el) => el.hasPrimaryLocation)
        .map((el) => el.hasPrimaryLocation[0].country)
    );
    setStaticStatistics({
      uniqueOrganizations: orgCounter,
      organizationMaxType: orgMaxTypeName.toLowerCase(),
      ransomAgainstHospitals: ransomAgainstHospitals,
      otherMalwareAgainstHospitals: otherMalwareAgainstHospitals,
      unknownAgainstHospitals: unknownAgainstHospitals,
      eventsPerCountry: eventsPerCountry,
      incidentsByPatientCareService: incidentsByPatientCareService,
      averageOperationalImpactDuration: averageOperationalImpactDuration,
      allIncidents: attacks.length,
      countries: countries.size,
    });
  }

  const handleClose = () => setShow(false);
  const handleShow = (key: string) => {
    const openElement = state.items.find((element) => element._key === key);

    if (openElement) {
      setShow(true);
      setState({ ...state, openElement: openElement });
    } else {
      console.error("No element found with id: " + key);
    }
  };

  const [incidents, setIncidents] = useRecoilState(incidentsResultsAtom);

  const [attackFilter] = useRecoilState(attackFilterAtom);

  const [state, setState] = React.useState<State>({
    initialItems: [],
    items: [],
    lastPublished: "",
  });

  useEffect(() => {
    getExploreData().then(
      (result) => {
        const sortedResult = sortResults(result[0].events);

        setState({
          ...state,
          isLoaded: true,
          lastPublished: result[0].lastPublished[0],
          initialItems: sortedResult,
          items: sortedResult,
        });
        setIncidents(sortedResult);
        calculateStaticStatistics(sortedResult);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.error(error.name + ": " + error.message);
        setState({
          ...state,
          isLoaded: true,
          error,
        });
      }
    );
  }, [page]);

  useEffect(() => {
    if (!state.initialItems) {
      return;
    }

    const selectedAttacks = filterAttacks(state.initialItems, attackFilter);

    // If no input is given
    if (
      attackFilter.selectedAttackCategories.length === 0 &&
      attackFilter.selectedAttackTypes.length === 0 &&
      attackFilter.selectedRansomwareOperator.length === 0 &&
      attackFilter.selectedSubSectors.length === 0 &&
      attackFilter.selectedOrganizationTypes.length === 0 &&
      attackFilter.selectedRegions.length === 0 &&
      attackFilter.selectedCountries.length === 0 &&
      !attackFilter.fromDate &&
      !attackFilter.toDate
    ) {
      setState({
        ...state,
        items: [...state.initialItems],
      });
      // Else If Some input is given return the results
    } else {
      setState({
        ...state,
        items: selectedAttacks,
      });
    }
  }, [attackFilter]);

  useEffect(() => {
    if (state.initialItems) {
      calculateDynamicStatistics(state.items);
    }
  }, [state]);

  return (
    <div className="visualizations">
      {!state.isLoaded && <Loading />}

      <div className="title offset-lg-2 col-lg-8 col-md-12">
        <h1 className="font-extralight">Cyber Incident Tracer #HEALTH</h1>
      </div>

      <div className="offset-lg-2 col-lg-8 col-md-12">
        <p className="text-block topic-break">
          CIT #HEALTH contains data on
          <strong> {staticStatistics.allIncidents} </strong>
          cyberattacks against the healthcare sector across
          <strong> {staticStatistics.countries} </strong>
          countries. While this is only a fraction of the full scale of the
          problem, the CIT #HEALTH starts to bridge the current gap in our
          understanding of the human impact of cyberattacks. Help us fill the
          information gaps to draw deeper insights from the data and shed better
          light on the threat to the sector and its societal impact.
        </p>
      </div>

      <FilterForm />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Incident details and impact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AttackPage attack={state.openElement as Attack} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div className="visuals-tabs">
        <div className="offset-lg-2 col-lg-8">
          <div
            className={`visual-tab-button ${
              switches.tabSwitch ? "active" : ""
            }`}
            onClick={goToVisuals}
          >
            Data visualization
          </div>
          <div
            className={`visual-tab-button ${
              switches.tabSwitch ? "" : "active"
            }`}
            onClick={goToTable}
          >
            Collected data
          </div>
        </div>
      </div>

      <div style={{ display: !switches.tabSwitch ? `` : `none` }}>
        <AttackTable data={state.items} handleShow={handleShow} />
      </div>

      <div
        className="data-visualization"
        style={{ display: switches.tabSwitch ? `` : `none` }}
      >
        <div className="offset-lg-2 col-lg-8 col-md-12">
          <FilterPeriods
            attacks={state.items}
            lastPublished={state.lastPublished}
          />

          <IncidentsOverTime attacks={state.items} />

          <IncidentsInSpace attacks={state.items} />

          <Chart>
            <ChartTitle title="Targeted Organizations" />
            <TreeMap attacks={state.items} />
          </Chart>

          <h3>Impact and Harm</h3>

          <div className="row">
            <div className="col-lg-6">
              <h4 className="subtitle">on Individuals</h4>

              <ParliamentChart
                attacks={state.items}
                type="RecordsBreached"
                title="Number of records breached per incident"
              />

              <PercentageBarChart
                percentages={dynamicStatistics.sumHcPatientsRedirected}
                title="Incidents resulting in patients redirected"
                img="green-01.png"
              />

              <PercentageBarChart
                percentages={dynamicStatistics.sumHcAppointmentsCancelled}
                title="Incidents resulting in appointment cancellations"
                img="green-03.png"
              />
            </div>

            <div className="col-lg-6">
              <h4 className="subtitle">on Organizations</h4>
              <ParliamentChart
                attacks={state.items}
                type="OperationalImpactDuration"
                title="Operational impact duration per incident"
              />

              <PercentageBarChart
                percentages={dynamicStatistics.sumHcSystemsOffline}
                hideDisclaimer={true}
                title="Incidents resulting in systems going offline"
                img="green-02.png"
              />
              <PercentageBarChart
                percentages={dynamicStatistics.sumExposedOrLeaked}
                hideDisclaimer={true}
                title="Incidents resulting in the exposure or leak of data"
                img="green-04.png"
              />
            </div>
          </div>

          <ImpactAcrossIncidents attacks={state.items} />

          <h3>Ransomware</h3>
          <div className="flex">
            <small className="text-center">
              *Non-applicable incidents (ie - non-ransomware incidents) are
              excluded from the graphics in this section
            </small>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <PercentageBarChart
                percentages={dynamicStatistics.sumRansomPaid}
                hideDisclaimer={true}
                title="Proportion of ransomware incidents where the ransom was reportedly paid"
              />
            </div>

            <div className="col-lg-6">
              <PercentageBarChart
                percentages={dynamicStatistics.sumPublishedOnline}
                hideDisclaimer={true}
                title="Proportion of ransomware incidents where the data was published online"
              />
            </div>
          </div>

          <Chart>
            <ChartTitle title="Incidents by top 10 ransomware operators over time (by month)" />
            <BubbleTimelineGrid attacks={state.items} />
          </Chart>

          <Chart>
            <ChartTitle title="Ransomware operator's alleged involvement in incidents color-coded by sub-Sector" />
            <NetworkGraphChart attacks={state.items} />
          </Chart>
        </div>
      </div>
    </div>
  );
}
