const split = (classes: string) => classes.split(" ").filter((c) => !!c);

export function overrideClassNames(defaults: string, classes?: string) {
  if (!classes) {
    return defaults;
  }

  const prefixes = split(classes).map((c) => c.split("-")[0]);
  const fromDefaults = split(defaults).filter(
    (c) => !prefixes.includes(c.split("-")[0])
  );

  return `${fromDefaults.join(" ")} ${classes}`;
}

export function getRandomId() {
  return Math.random().toString(36).substr(2);
}

export const credits = {
  enabled: true,
  href: "https://cyberpeaceinstitute.org",
  text: "© CyberPeace Institute",
};
