import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import colorDictionary from "../../utils/colors/ColorDictionary";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);

require("highcharts/modules/treemap")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

function returnChartOptions(data) {
  return {
    chart: {
      type: "treemap",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      type: "datetime",
    },

    yAxis: {
      title: {
        text: "Average Order",
      },
    },

    plotOptions: {},

    series: [
      {
        type: "treemap",
        layoutAlgorithm: "sliceAndDice",
        alternateStartingDirection: true,
        dataLabels: {
          enabled: true,
          color: "#ffffff",
        },
        levels: [
          {
            level: 1,
            layoutAlgorithm: "sliceAndDice",
            dataLabels: {
              enabled: true,
              align: "left",
              verticalAlign: "top",
              style: {
                textOutline: "0px",
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          },
        ],
        data: data,
      },
    ],
  };
}

function getSubSectors(attacks) {
  return [
    ...new Set(
      attacks.map((attack) => attack.involvedOrganization[0].primarySubSector)
    ),
  ];
}

function getSeries(attacks) {
  const subSectors = getSubSectors(attacks);

  return subSectors.reduce((acc, subSector) => {
    acc[subSector] = {
      id: subSector,
      name: subSector,
    };

    const attackSumByOrganizationTypes = attacks
      .filter(
        (attack) =>
          attack.involvedOrganization[0].primarySubSector === subSector
      )
      .reduce((acc2, item) => {
        const orgType = item.involvedOrganization[0].organizationType;
        acc2[orgType] = acc2[orgType] ?? {
          value: 0,
          name: orgType,
          parent: subSector,
        };
        acc2[orgType].value++;

        return acc2;
      }, {});

    const sortedAttackSumByOrganizationTypes = Object.values(
      attackSumByOrganizationTypes
    ).sort((a, b) => b.value - a.value);

    sortedAttackSumByOrganizationTypes.forEach((attackSum, index) => {
      attackSum.color = getColor(attackSum, index);
      acc[attackSum.name] = attackSum;
    });

    return acc;
  }, {});
}

function getColor(attackSum, index) {
  return attackSum.parent.toLowerCase() === "other"
    ? "#919191"
    : colorDictionary.SubSector[attackSum.parent.toLowerCase()];
}

class TreeMap extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);

    this.allowChartUpdate = true;

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: returnChartOptions([]),
      shouldRender: true,
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  refreshData() {
    const attacks = this.props.attacks;
    const data = Object.values(getSeries(attacks));

    setTimeout(() => {
      this.setState({
        ...this.state,
        shouldRender: true,
      });
    }, 1000);
    this.setState({
      chartOptions: returnChartOptions(data),
      shouldRender: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      this.props.attacks &&
      prevProps.attacks !== this.props.attacks
    ) {
      this.refreshData();
    }
  }

  render() {
    const { chartOptions, shouldRender } = this.state;

    return (
      <div className="highcharts-outer-container">
        {
          // Render the HighchartsReact element conditionally as Highcharts has a bug
          // when updating data on a graph that supports drilldown
          shouldRender && (
            <HighchartsReact
              callback={this.afterChartCreated}
              highcharts={Highcharts}
              options={chartOptions}
              allowChartUpdate={this.allowChartUpdate}
              updateArgs={[true, true, true]}
            />
          )
        }
      </div>
    );
  }
}

export default TreeMap;
