import React, { PropsWithChildren, useCallback, useEffect } from "react";
import Fade from "../../lib/Fade";
import Teaser, { TeaserLayout, TeaserProps } from "../../lib/Teaser";
import Impact from "../../lib/Impact";
import { Parallax } from "react-parallax";
import Stat, { StatStyle } from "../../lib/Stat";
import formatMillion, { getFormatedDate } from "../../utils/format";
import Blink from "../../lib/Blink";
import { getOrganizationType } from "../../lib/i18n/organization-types";
import LinkButton from "../../lib/LinkButton";
import { InView } from "react-intersection-observer";
import { getHomeData, HomeData } from "../../lib/api";
import OutOfDate from "../../lib/OutOfDate";

interface Props {
  setShowActBubble: (inView: boolean) => void;
}

const teasers: TeaserProps[] = [
  {
    image: "/images/green-01.png",
    title: "urgent medical care",
    description: "and your ambulance is diverted",
    layout: TeaserLayout.ImageOnRight,
  },
  {
    image: "/images/green-02.png",
    title: "a prescription",
    description: "and your medical records are inaccessible",
  },
  {
    image: "/images/green-03.png",
    title: "cancer treatment",
    description: "and radiation equipment is disrupted",
    layout: TeaserLayout.ImageOnRight,
  },
  {
    image: "/images/green-04.png",
    title: "therapy",
    description: "and your confidential session  is published online",
  },
  {
    image: "/images/green-05.png",
    title: "to be vaccinated",
    description: "and vaccines are unavailable due to supply chain disruptions",
    layout: TeaserLayout.ImageOnRight,
  },
];

function Container(props: PropsWithChildren<{}>) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const onResize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  if (width > 768) {
    return (
      <Parallax
        bgImage="/images/background-black.png"
        strength={1000}
        className="bg-contain home-section-numbers"
      >
        {props.children}
      </Parallax>
    );
  } else {
    return (
      <div
        className="bg-cover"
        style={{ backgroundImage: "url(/images/background-black.png)" }}
      >
        {props.children}
      </div>
    );
  }
}

export default function Chapter2(props: Props) {
  const [data, setData] = React.useState<HomeData | null>(null);
  const [showDialog, setShowDialog] = React.useState<
    "open" | "closed" | undefined
  >(undefined);

  useEffect(() => {
    getHomeData().then((d) => setData(d));
  }, []);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowDialog("closed");
      document.removeEventListener("keydown", escFunction, false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
  const handleCloseDialog = () => {
    setShowDialog("closed");
  };

  console.log({ showDialog });

  return (
    <InView
      onChange={(inView) => props.setShowActBubble(inView)}
      threshold={0.1}
    >
      <section className="my-4 md:my-10 py-8 md:py-16 home-section home-section-intro">
        <InView
          threshold={1}
          onChange={(inView) => inView && !showDialog && setShowDialog("open")}
        >
          {({ ref }) => (
            <Fade>
              <h2
                ref={ref}
                className="px-8 md:px-0 text-4xl md:text-6xl font-extrabold text-cpi"
              >
                Cyberattacks on healthcare are attacks on people.
              </h2>
            </Fade>
          )}
        </InView>

        <dialog
          open={showDialog === "open"}
          className="fixed z-50 top-0 bottom-0 left-0 right-0 text-lg p-0"
        >
          <div className="relative w-full bg-sec py-4 px-8">
            <button
              onClick={handleCloseDialog}
              className="bg-none outline-none border-0 absolute top-4 right-4 text-white text-5xl"
            >
              &times;
            </button>
            <div className="mt-4 max-h-96 lg:max-h-screen overscroll-contain-y overflow-y-auto">
              <OutOfDate />
            </div>
          </div>
        </dialog>
        <Fade>
          <h3 className="my-10 text-cpi text-3xl">Imagine you need…</h3>
        </Fade>

        <div className="max-w-sm">
          {teasers.map((t) => (
            <Fade key={t.title}>
              <Teaser {...t} />
            </Fade>
          ))}
        </div>

        <Impact
          className="text-cpi"
          line1="This is happening today."
          line2="Cyberattacks have made this a reality."
          line3="Ready to face the numbers?"
        />
      </section>

      <Container>
        <section className="py-16 px-4 md:px-0 home-section text-white">
          <Fade>
            <p className="text-3xl text-white">
              <small>Disruptive cyberattacks tracked since June 2020.</small>
            </p>
            <p className="mb-16">
              Last updated: {getFormatedDate(data?.lastPublished)}
              <br />
              <small>* updates on a quarterly basis</small>
            </p>
          </Fade>

          <Fade className="max-w-full">
            <hr className="white my-16" />
          </Fade>

          <Fade>
            <Stat
              value={data?.attacks}
              contentAfter="against healthcare organizations"
            />
          </Fade>

          <Fade>
            <Stat value={data?.countries} contentAfter="countries" />
          </Fade>

          <Fade>
            <Stat
              value={formatMillion(data?.impactRecords)}
              contentBefore="Over"
              contentAfter={
                <>
                  records breached containing
                  <br />
                  <Blink
                    items={[
                      "medical data",
                      "social security numbers",
                      "contact details",
                      "donor details",
                      "diagnostics",
                      "HIV status",
                      "financial information",
                      "corporate data",
                      "medical imagery",
                      "identity cards",
                      "fertility status",
                    ]}
                  />
                </>
              }
              image={"/images/white-06.svg"}
              isFloat={true}
              valueSuffix=" million"
            />
          </Fade>

          <Fade>
            <Stat
              value={data?.impactOffline}
              contentBefore="Systems went offline in"
              contentAfter="of incidents"
              image="/images/white-04.svg"
              style={StatStyle.BigHugeSmall}
              valueSuffix="%"
            />
          </Fade>

          <Fade>
            <Stat
              value={data?.impactLeaked}
              contentBefore="Data leaked or exposed in"
              contentAfter="of incidents"
              style={StatStyle.BigHugeSmall}
              valueSuffix="%"
            />
          </Fade>

          <Fade className="max-w-full">
            <hr className="white my-16" />
          </Fade>

          <Fade>
            <Stat
              value={data?.attacksPatientCareServices}
              contentAfter={
                <>
                  against patient care services
                  <br />
                  <Blink
                    items={(data?.organizationTypes ?? []).map((e) =>
                      getOrganizationType(e)
                    )}
                  />
                </>
              }
            />
          </Fade>

          {/*TODO number count up using real values*/}
          <Fade>
            <Stat
              value={data?.impactDisruptions}
              contentBefore="Operational disruption lasted a matter of hours to"
              contentAfter="days"
              image="/images/white-01.svg"
              style={StatStyle.BigHugeSmall}
              withCountUp={true}
            />
          </Fade>

          <Fade>
            <Stat
              value={data?.impactDivertions}
              contentBefore="Ambulances or incoming patients diverted in"
              contentAfter="of incidents"
              image="/images/white-02.svg"
              style={StatStyle.BigHugeSmall}
              valueSuffix="%"
            />
          </Fade>

          <Fade>
            <Stat
              value={data?.impactPostponed}
              contentBefore="Appointments or surgeries cancelled in"
              contentAfter="of incidents"
              image="/images/white-03.svg"
              style={StatStyle.BigHugeSmall}
              valueSuffix="%"
            />
          </Fade>

          <Fade className="max-w-full">
            <hr className="white my-16" />
          </Fade>

          <Fade>
            <h3 className="text-3xl text-white">This means...</h3>
          </Fade>

          <Fade>
            <p className="my-16">
              Disruptions to patient care endanger lives and create stress and
              suffering for patients and medical professionals. Trust in
              healthcare is eroded. Cyberattackers prey on the fear and
              vulnerabilities of their targets. Look at the data.
            </p>
          </Fade>

          <Fade>
            <LinkButton className="my-16 px-16 py-4" to="/explore">
              Access Data
            </LinkButton>
          </Fade>
        </section>
      </Container>
    </InView>
  );
}
