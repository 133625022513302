import React, { PropsWithChildren } from "react";

interface Props {
  number: number;
}

export default function TitleWithNumber(props: PropsWithChildren<Props>) {
  return (
    <h3
      className={`flex items-center gap-4 ${
        props.number > 1 ? "border-t border-cpi pt-4" : ""
      }`}
    >
      <span className="inline-block rounded-full flex flex-none justify-center items-center h-9 w-9 bg-cpi text-white font-extrabold">
        {props.number}.
      </span>
      {props.children}
    </h3>
  );
}
