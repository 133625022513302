import React from "react";
import Section from "../../lib/Section";
import Page from "../../lib/Page";
import { Link } from "react-router-dom";
import A from "../../lib/A";

function Methodology() {
  return (
    <Page
      title="Methodology"
      intro={
        <>
          <p>
            The Cyber Incident Tracer #HEALTH is a data platform that displays
            cyberattacks on the healthcare sector. The following sections
            outline the methodology used for the platform’s data collection and
            classification. If you have a question on methodology not addressed
            here, please do reach out via Contact Us and we will do our best to
            respond.
          </p>
          <p>
            If you use the data or information on this platform for any
            analysis, publication, presentation or any other form of
            dissemination, please credit the source as the CyberPeace Institute.
          </p>
        </>
      }
    >
      <Section title="About the Data">
        <h3 className="mb-2 font-extrabold">Definition of a cyberattack</h3>
        <p>
          For the purposes of the Cyber Incident Tracer, a cyberattack is a
          disruptive attack conducted by a threat actor using a computer network
          or system with the intention to cause damage (technical, financial,
          reputational or other).
        </p>

        <h3 className="mt-8 mb-2 font-extrabold">
          Data sources, categorization and frequency of updates
        </h3>

        <p>
          Data in the CIT #HEALTH is collected from publicly available sources.
          The CyberPeace Institute cross references information about a
          cyberattack to ensure accuracy. If we receive information about
          incorrect data we will verify and correct this.
        </p>

        <p>
          The CIT #HEALTH lists all sources that have been used to populate data
          on each individual attack including URLs pointing to the articles. The
          CyberPeace Institute is constantly updating the list of sources it
          monitors. Examples of sources used to date include:
        </p>

        <ul className="list-disc pl-8">
          <li>
            <A href="https://www.hackmageddon.com/category/security/cyber-attacks-timeline/">
              Hackmageddon
            </A>{" "}
            cyber attack timelines;
          </li>
          <li>
            <A href="https://konbriefing.com/en-topics/cyber-attacks.html">
              KonBriefing
            </A>{" "}
            List of ransomware & cyber attacks;
          </li>
          <li>
            University of Maryland{" "}
            <A href="https://cissm.liquifiedapps.com/#table">CISSM</A> Cyber
            Attacks Database;
          </li>
          <li>
            <A href="https://ransomwaremap.de/">Ransomwaremap</A> map of
            ransomware attacks;
          </li>
          <li>
            <A href="https://sites.temple.edu/care/ci-rw-attacks/">
              Temple University
            </A>{" "}
            critical infrastructure ransomware attacks;
          </li>
          <li>
            <A href="https://darktracer.com/">DarkTracer</A> Ransomware
            Monitoring Platform;
          </li>
          <li>
            Cybersecurity News & Blogs, such as Cyware News, The Daily Swig,
            (Health) IT;
          </li>
          <li>
            Sector specific News / Blogs such as Databreaches.net, Beckers
            Hospital Review, HIPAA Journal, Health IT Security, IT Governance;
          </li>
          <li>
            Mainstream News sites in English (high maturity of language skill),
            French (high), German (medium), Russian (low);
          </li>
          <li>Twitter;</li>
          <li>
            <A href="https://ocrportal.hhs.gov/ocr/breach/breach_report.jsf">
              US Notices to the Secretary of HHS Breach of Unsecured Protected
              Health Information
            </A>{" "}
            (for confirmation of US incidents).
          </li>
        </ul>

        <p>
          Data sources are categorized according to the proximity of the
          reporting source to the target organization:
        </p>

        <ul className="list-disc pl-8">
          <li>
            <strong>Primary sources</strong>: firsthand information provided by
            the target organization or reporting by an official public entity;
          </li>
          <li>
            <strong>Secondary sources</strong>: retrospective or ongoing
            reporting in the form of articles or blog posts;
          </li>
          <li>
            <strong>Tertiary sources</strong>: summarizing or aggregating
            sources, such as data sets or reports.
          </li>
        </ul>

        <p>
          We cross reference information about a cyberattack to ensure that the
          CIT is a relevant resource. If we receive information about incorrect
          data we will verify and correct this.
        </p>

        <p>
          Data on cyberattacks against healthcare is updated on a bi-weekly
          basis.
        </p>

        <h3 className="mt-8 mb-2 font-extrabold">Scope of data collection</h3>

        <p>
          For this BETA version, the CIT #HEALTH contains information on
          disruptive cyberattacks targeting the healthcare sector from June 2020
          to the present day. The <Link to="/glossary">Glossary</Link> provides
          further information on the sub type of attacks recorded.
        </p>

        <p>
          Cyberattacks against the following types of healthcare facilities are
          included:
        </p>

        <ul className="list-disc pl-8">
          <li>
            <strong>Patient care services</strong>: care provider, clinic,
            healthcare network, hospital, medical specialist, mental health &
            substance abuse, national health system
          </li>
          <li>
            <strong>Medical Manufacturing & Development</strong>: medical
            manufacturing, medical research and development (R&D)
          </li>
          <li>
            <strong>Pharmaceuticals</strong>: pharmacies, pharmaceutical and
            biotech
          </li>
          <li>
            <strong>Other</strong>: ambulance services, electronic health
            records (EHR) management / practice management (PM) vendors,
            laboratories & diagnostics, telehealth
          </li>
        </ul>

        <p>
          The current data collection does not include data breaches due to the
          challenges of collecting data on a global scale. It also excludes
          supply chain attacks, in which healthcare organizations were
          indirectly targeted, as well as cyberespionage as these cases have
          limited public information.
        </p>

        <h3 className="mt-8 mb-2 font-extrabold">Data classification</h3>

        <p>
          As we are reliant on publicly available data, the data on cyberattacks
          used in the CIT #HEALTH has been given a classification of certainty
          based on the reliability of the information source. The classification
          levels are:
        </p>

        <ul className="list-disc pl-8">
          <li>
            <div className="attack-certainty-box confirmed"></div>
            <strong>Confirmed</strong>: attacks in this category are based on
            official government reports, official press releases by the targeted
            organization, official letters to patients/customers by the target
            organization or the government.
          </li>
          <li>
            <div className="attack-certainty-box probable"></div>
            <strong>Probable</strong>: attacks in this category are based on
            media reports of a press conference by the targeted organization,
            social media communication by the targeted organization or quotes
            from the targeted organization’s staff in media articles.
          </li>
          <li>
            <div className="attack-certainty-box possible"></div>
            <strong>Possible</strong>: attacks in this category are based on
            media reports with no direct reference to first hand source
            information. This can be in the form of a news article that mentions
            a letter sent to patients or a blog post that references a statement
            published by the targeted organization, but no direct record of this
            evidence is available. This category also includes data published by
            ransomware operators online with no further corroborating
            information.
          </li>
        </ul>

        <p>
          The CIT does not include data related to ‘<strong>Hearsay</strong>’
          attacks which contain uncorroborated information originating from a
          third party, i.e. as a result of media reporting through the
          allegation of a third party.
        </p>

        <h3 className="mt-8 mb-2 font-extrabold">Safeguards</h3>

        <p>
          Safeguarding healthcare organizations and the associated victims
          during data processing and publication of information in the Cyber
          Incident Tracer is important. Protecting people is at the core of the
          mission of the CyberPeace Institute. We have taken the necessary
          precautions to ensure that we do not name individual targets of
          cyberattacks*, we do not specify which targeted organizations had
          their data leaked online, and we do not disclose which target
          organization paid a ransom.
        </p>

        <p>
          The CyberPeace Institute recognizes the sensitivity of information on
          cyberattacks and underlines the importance to not re-victimize the
          targeted organizations. We work towards minimizing the reputational
          damage that cyberattacks can cause and the risk of sowing distrust in
          the healthcare sector, which is already under significant strain
          during the COVID-19 global pandemic. It is critical to raise awareness
          of the importance of the protection of data subjects whose data has
          been breached and may be accessible online.
        </p>

        <p>
          <i>
            *Targeted organizations may be named in publicly available sources
            for which website URLs are listed in the sources tab of the incident
            records.
          </i>
        </p>
      </Section>

      <Section title="Known Limitations">
        <p>
          The CyberPeace Institute aims to collect, organize and visualize data
          on cyberattacks on healthcare in order to make seemingly intractable
          problems - like cyberattacks - understandable. Making intractable
          problems understandable can support the recognition and development of
          solutions, norms and standards.
        </p>

        <p>
          We also recognize that there are limitations to such efforts in
          particular, we underline the following areas:
        </p>

        <ul className="list-disc pl-8">
          <li>
            <strong>Data Completeness</strong>: Due to differences in public
            reporting / disclosure of cyberattacks, the dataset is not a
            complete representation of all cyberattacks on healthcare. Specific
            limitations we are acutely aware of include:
            <ul className="list-disc pl-8">
              <li>resource constraints</li>
              <li>language constraints</li>
              <li>reliance on publicly accessible data</li>
            </ul>
            Due to these constraints the CIT primarily collects data on
            disruptive attacks.
          </li>
          <li>
            <strong>Data Delay</strong>: complete and accurate information about
            cyberattacks and their impact can take years before it is
            accessible;
          </li>
          <li>
            <strong>Data Validation</strong>: Currently, we are not verifying
            the public information about a cyberattack with the affected
            healthcare organizations. We do not have a process for the
            validation or verification of information relating to incidents with
            targeted organizations. The CyberPeace Institute is committed to
            collecting the testimonies from victims of cyberattacks and
            envisages that reaching out to these organizations could help to
            fill information gaps notably on the impact they had on their
            organization and the people they serve. We will endeavour to build
            this capacity.
          </li>
        </ul>
      </Section>

      <Section title="Recognition and Thanks">
        <p>
          The CyberPeace Institute would like to recognize the significant and
          ongoing effort of four individuals and organizations who have helped
          to identify and track cyberattacks against the healthcare sector. They
          have provided data for the Institute to use and make publicly
          available in order to support the goal of creating a reliable data
          source for all to access. Thank you for your support:
        </p>

        <ul className="list-disc pl-8">
          <li>
            Paolo Passeri from Hackmageddon{" "}
            <A href="https://www.hackmageddon.com/">
              https://www.hackmageddon.com/
            </A>
          </li>
          <li>
            DarkTracer{" "}
            <A href="https://darktracer.com/">https://darktracer.com/</A>
          </li>
          <li>
            DataBreaches LLC{" "}
            <A href="https://www.databreaches.net/about/">
              https://www.databreaches.net/about/
            </A>
          </li>
          <li>
            Rege, A. (2020). "Critical Infrastructure Ransomware Incident
            Dataset". Version 10.5. Temple University. Online at{" "}
            <A href="https://sites.temple.edu/care/downloads/">
              https://sites.temple.edu/care/downloads/
            </A>
            . Funded by National Science Foundation CAREER Award #1453040.
          </li>
        </ul>
      </Section>
    </Page>
  );
}

export default Methodology;
