import React from "react";

export enum TitleWithButtonsType {
  Left,
  Right,
}

interface ButtonProps {
  active: boolean;
  onClick: VoidFunction;
  text: string;
}
function Button(props: ButtonProps) {
  const extra = props.active
    ? "bg-cpi text-white"
    : "bg-white text-cpi border border-cpi";
  return (
    <button
      className={`rounded-full py-1 px-4 ${extra}`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

interface Props {
  onClick?: (type: TitleWithButtonsType) => void;
  title: string;
  buttonLeft?: string;
  buttonRight?: string;
}
export default function ChartTitle(props: Props) {
  const [active, setActive] = React.useState(TitleWithButtonsType.Left);

  const onClick = (type: TitleWithButtonsType) => {
    setActive(type);
    if (props.onClick) {
      props.onClick(type);
    }
  };

  return (
    <div className="border-t border-cpi2 pt-2 mb-4 flex flex-col md:flex-row justify-between">
      <h4 className="text-lg">{props.title}</h4>
      {props.onClick && (props.buttonLeft || props.buttonRight) && (
        <div className="flex gap-4">
          {props.buttonLeft && (
            <Button
              active={active === TitleWithButtonsType.Left}
              onClick={() => onClick(TitleWithButtonsType.Left)}
              text={props.buttonLeft}
            />
          )}
          {props.buttonRight && (
            <Button
              active={active === TitleWithButtonsType.Right}
              onClick={() => onClick(TitleWithButtonsType.Right)}
              text={props.buttonRight}
            />
          )}
        </div>
      )}
    </div>
  );
}
