import React from "react";
import Select, { ValueType } from "react-select";
import { FilterValue } from "../../atoms";

interface Props<T> {
  name: string;
  placeholder: string;
  value: T[];
  onChange: (val: ValueType<T, true>) => void;
  options: T[];
}

export default function FilterValueSelect(props: Props<FilterValue>) {
  return (
    <Select
      name={props.name}
      className="select ml-2 mr-2"
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      options={props.options}
      isMulti={true}
    />
  );
}
