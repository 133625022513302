import React from "react";
import { overrideClassNames } from "./utils";
import Fade from "./Fade";

interface Props {
  className?: string;
  line1: string;
  line2: string;
  line3: string;
  sameSize?: boolean;
}

const defaultClassNames = "my-8 px-8 md:my-20 font-bold";

enum Sizes {
  Small,
  Medium,
  Big,
}
const sizes = {
  [Sizes.Small]: "text-3xl md:text-4xl",
  [Sizes.Medium]: "text-4xl md:text-5xl",
  [Sizes.Big]: "text-5xl md:text-6xl",
};

export default function Impact(props: Props) {
  const { className = "", line1, line2, line3, sameSize } = props;
  const base = overrideClassNames(defaultClassNames, className);
  return (
    <>
      <Fade>
        <p className={`${base} ${sameSize ? "text-3xl" : sizes[Sizes.Small]}`}>
          {line1}
        </p>
      </Fade>
      <Fade>
        <p className={`${base} ${sameSize ? "text-3xl" : sizes[Sizes.Medium]}`}>
          {line2}
        </p>
      </Fade>
      <Fade>
        <p className={`${base} ${sameSize ? "text-3xl" : sizes[Sizes.Big]}`}>
          {line3}
        </p>
      </Fade>
    </>
  );
}
