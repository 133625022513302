import React from "react";
import Page from "../../lib/Page";
import Section from "../../lib/Section";
import { getRandomId } from "../../lib/utils";

interface Definition {
  title: string;
  image?: string;
  content: React.ReactNode;
}

const incidentCategories: Definition[] = [
  /*{
    title: "Data Breach",
    content:
      "Incidents in which an unauthorized actor gained access to, stole, or published data from a target organization.",
  },*/
  {
    title: "Disruptive Attack",
    content:
      "Incidents that have had an operational and/or physical impact on the target organization (disruptive attacks often include elements of a data breach).",
  },
];
const incidentTypes: Definition[] = [
  /*{
    title: "Compromised Account",
    content: (
      <>
        A form of data breach where a target’s computer credentials have been
        compromised. For more information see{" "}
        <a href="https://attack.mitre.org/techniques/T1586/">
          MITRE ATT&CK Framework
        </a>
        .
      </>
    ),
    image: "/images/attack-account-takeover.png",
  },
  {
    title: "Data Theft",
    content:
      "A form of data breach that involves stealing of data from and through a target’s network.",
    image: "/images/attack-data-theft.png",
  },*/
  {
    title: "Distributed Denial-of-Service (DDoS)",
    content:
      "DDoS is an attack technique to flood a network, service or server with excessive traffic to cause it to cease functioning normally. It is said to be distributed when the source of the attack is composed of a multitude of devices or systems.",
    image: "/images/attack-ddos.png",
  },
  {
    title: "Defacement",
    content:
      "The illicit or unauthorized modification of the appearance and content of a target’s websites and/or web applications.",
    image: "/images/attack-defacement.png",
  },
  {
    title: "Other",
    content:
      "Events that do not fall under any other categories or are unspecified.",
    image: "/images/attack-other.png",
  },
  {
    title: "Malware (except Ransomware)",
    content:
      "The use of malicious software (other than ransomware) to either infiltrate, maintain a foothold, or exploit a system and its data.\n",
    image: "/images/attack-malware-other.png",
  },
  {
    title: "Ransomware",
    content:
      "A type of malware that encrypts a target's data with the aim to extort a ransom. This also includes double extortion, whereby the target’s data is both encrypted and exfiltrated. If the target refuses to pay the ransom, the attacker can threaten to publish the data. Unconfirmed ransomware incidents are those that have been linked to ransomware operators (e.g. via their claims and dump sites) but have not been publicly confirmed.",
    image: "/images/attack-ransomware.png",
  },
];
const impactsOnIndividuals: Definition[] = [
  {
    title: "Appointments Cancelled",
    content:
      "Indicates if an attack resulted in the cancellation of appointments, including surgeries.",
  },
  {
    title: "Breach Size / Records Breached",
    content:
      "The number of records or individuals that were affected by the incident.",
  },
  {
    title: "Data Published Online",
    content:
      "Indicates if the event resulted in the target’s data being ‘dumped’ or displayed on a ransomware dumpsite.",
  },
  {
    title: "Patients Redirected",
    content:
      "Indicates if incoming patients had to be redirected to another patient care facility.",
  },
];
const impactsOnOrganizations: Definition[] = [
  {
    title: "Data Exposed / Leaked",
    content:
      "Indicates if the attacker had access to, stole, or published data from the target organization.",
  },
  {
    title: "Operational Impact Duration",
    content:
      "The number of known days that an attack has impacted the operational activities of healthcare services.",
  },
  {
    title: "Ransom Requested",
    content: "Indicates if a ransom was requested throughout the event.",
  },
  {
    title: "Systems Offline",
    content:
      "Indicates whether or not an attack forced IT systems offline or resulted in the inaccessibility of systems (e.g. patient portals, telephone, email, information systems, etc).",
  },
];

interface Props {
  types: Definition[];
}
function Definitions({ types }: Props) {
  const id = getRandomId();
  return (
    <dl>
      {types.map(({ title, image, content }, index) => (
        <React.Fragment key={`def-${id}-${index}`}>
          <dt className="mb-2">{title}</dt>
          <dd className="mb-4">
            {image && (
              <img className="w-16 h-16 my-2" src={image} alt={title} />
            )}
            {content}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
}

export default function Glossary() {
  return (
    <Page
      title="Glossary"
      intro={
        <p>
          The types of attacks which are monitored and included in the Cyber
          Incident Tracer #HEALTH are disruptive attacks with some reported
          impact on the target organization and / or its patients and customers.
          The Glossary provides further information on the sub type of attacks
          recorded.
        </p>
      }
    >
      <Section title="Cyber Incidents Terminology">
        <h3 className="mb-2 font-extrabold text-cpi">Incident Categories</h3>
        <Definitions types={incidentCategories} />
        <h3 className="mb-2 font-extrabold text-cpi">Incident Types</h3>
        <Definitions types={incidentTypes} />
      </Section>

      <Section title="Impact of Cyber Incidents">
        <h3 className="mb-2 font-extrabold text-cpi">On Individuals</h3>
        <Definitions types={impactsOnIndividuals} />
        <h3 className="mb-2 font-extrabold text-cpi">On Organizations</h3>
        <Definitions types={impactsOnOrganizations} />
      </Section>
    </Page>
  );
}
