import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { overrideClassNames } from "./utils";

export enum StatStyle {
  SmallHugeBig,
  BigHugeSmall,
}

interface Props {
  className?: string;
  value?: number;
  contentBefore?: string;
  contentAfter: React.ReactNode;
  image?: string;
  isFloat?: boolean;
  style?: StatStyle;
  valuePrefix?: string;
  valueSuffix?: string;
  withCountUp?: boolean;
}

const defaultProps: Partial<Props> = {
  style: StatStyle.SmallHugeBig,
  valuePrefix: "",
  valueSuffix: "",
};

const valueSize = "text-7xl md:text-8xl";
const baseClassNames = "mb-16 md:mb-32 text-xl text-center";

export default function Stat(props: Props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const p = Object.assign({}, defaultProps, props);

  if (!p.value) {
    return null;
  }

  const sizes =
    p.style === StatStyle.SmallHugeBig
      ? { before: "text-xl", after: "text-2xl" }
      : { before: "text-2xl", after: "text-xl" };

  const val = p.isFloat ? p.value : parseInt(`${p.value}`);
  return (
    <div className={overrideClassNames(baseClassNames, p.className)} ref={ref}>
      {p.contentBefore && <div className={sizes.before}>{p.contentBefore}</div>}

      <span className={`font-thin ${valueSize}`}>{p.valuePrefix ?? ""}</span>
      <span className={`font-black ${valueSize}`}>
        {p.withCountUp ? (
          <CountUp
            duration={1}
            end={inView ? val : 0}
            decimals={p.isFloat ? 1 : 0}
            start={0}
          />
        ) : (
          val
        )}
      </span>
      <span className={`font-thin ${valueSize}`}>{p.valueSuffix ?? ""}</span>
      <div className={sizes.after}>{p.contentAfter}</div>

      {p.image && (
        <div className="flex justify-center">
          <img className="h-24 mt-8 md:mb-10" src={p.image} alt="" />
        </div>
      )}
    </div>
  );
}
