import React, { ReactElement } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-svg-tooltip";

import "./timelineGrid.css";
import Moment from "moment";
import { getWidth, handleResize } from "../../utils/window";
import { Attack } from "../types";
import { ScaleTime } from "d3";
import { Selection } from "d3-selection";

function getModalData(point: Point) {
  const stats = getStatsByCategory(point);
  return (
    <>
      <b>
        {point.eventDateFrom} - {point.value}{" "}
        {point.value > 1 ? "incidents" : "incident"}
      </b>
      <br />
      {stats.map((stat) => {
        return stat;
      })}
    </>
  );
}

function getStatsByCategory(point: Point) {
  const stats = Object.entries(point.attackCategoryCount).reduce(
    (acc: Record<string, ReactElement>, [category, value]) => {
      acc[category] = (
        <div key={`category-${category}`}>
          {category}:&nbsp;{value}
          <br />
        </div>
      );
      return acc;
    },
    {}
  );

  return Object.values(stats);
}

interface Props {
  attacks: Attack[];
}

interface Dimensions {
  width: number;
  height: number;
}

interface Point {
  value: number;
  attackCategoryCount: Record<string, number>;
  date: Date;
  eventDateFrom: string;
  category: string;
  ref: any;
  y: number;
  r: number;
}

interface AttackByCategory {
  category: string;
  counter: number;
}

function getAttacks(props: Props, dimensions: Dimensions) {
  if (props.attacks) {
    const radiusScale =
      dimensions.width > 3000
        ? d3.scaleLog().range([20, 65]).domain([1, 100])
        : d3.scaleLog().range([5, 36]).domain([1, 100]);

    let attacksByCategory: Record<string, AttackByCategory> = {};
    let attacksPerMonth: Record<string, Record<string, Point>> = {};
    let data: Array<Point> = [];
    props.attacks.forEach(function (attack, i) {
      if (!isApplicable(attack)) {
        return;
      }

      let eventDate = Moment(attack.eventDateFrom);
      let dateMonth = eventDate.format("MMMM - YYYY");
      //let dateMonth = eventDate.quarter(eventDate.quarter()).startOf('quarter').format("MMMM - YYYY");
      let ocgName = attack.involvedThreatActor[0].name;
      let attackCategory = attack.type;
      attacksPerMonth[dateMonth] = attacksPerMonth[dateMonth] ?? {};
      attacksPerMonth[dateMonth][ocgName] = attacksPerMonth[dateMonth][
        ocgName
      ] ?? {
        value: 0,
        attackCategoryCount: {},
        date: new Date(eventDate.format("YYYY-MM-01")),
        eventDateFrom: dateMonth,
        category: ocgName,
        ref: React.createRef(),
      };
      attacksPerMonth[dateMonth][ocgName].value++;

      attacksPerMonth[dateMonth][ocgName].attackCategoryCount[attackCategory] =
        attacksPerMonth[dateMonth][ocgName].attackCategoryCount[
          attackCategory
        ] ?? 0;
      attacksPerMonth[dateMonth][ocgName].attackCategoryCount[attackCategory]++;

      attacksByCategory[ocgName] = attacksByCategory[ocgName] ?? {
        category: ocgName,
        counter: 0,
      };
      attacksByCategory[ocgName].counter++;
    });

    const sortedAttackCategories = Object.values(attacksByCategory);
    sortedAttackCategories.sort((a, b) => b.counter - a.counter);

    const firstTenCategories = sortedAttackCategories.splice(0, 10);
    const yPositionDict: Record<string, number> = {};

    const numOfSectors = firstTenCategories.length + 1;

    firstTenCategories.forEach((entry, i) => {
      yPositionDict[entry.category] =
        ((dimensions.height - 50) / numOfSectors) * (i + 1);
    });

    Object.keys(attacksPerMonth).forEach((dateMonth) => {
      Object.keys(attacksPerMonth[dateMonth])
        .filter((category) => yPositionDict[category])
        .forEach((category) => {
          let entry = attacksPerMonth[dateMonth][category];
          entry.y = yPositionDict[category];
          entry.r = radiusScale(entry.value);
          data.push(entry);
        });
    });

    return {
      attacks: data,
      sectorsY: yPositionDict,
    };
  }

  return {};
}

function isApplicable(attack: Attack) {
  return (
    (attack.subType.toLowerCase() === "ransomware" ||
      attack.subType.toLowerCase() === "ransomware (unconfirmed)") &&
    attack.involvedThreatActor[0] &&
    attack.involvedThreatActor[0].type.toLowerCase() === "ransomware operator"
  );
}

function BubbleTimelineGrid(props: Props) {
  // set the dimensions and margins of the graph
  let margin = { top: 20, right: 10, bottom: 20, left: 110 };
  let width = getWidth(margin);
  let height = 450 - margin.top - margin.bottom;

  if (width > 3000) {
    margin = { top: 15, right: 5, bottom: 50, left: 480 };
    height = 1400 - margin.top - margin.bottom;
  }

  const containerRef = React.useRef(null);
  const svgRef = React.useRef(null);

  const [dimensions, setDimensions] = React.useState<Dimensions>({
    width,
    height,
  });
  const [zoomState, setZoomState] = React.useState<any>();

  React.useEffect(() => {
    const handler = () => handleResize(dimensions, setDimensions, margin);
    window.addEventListener("resize", handler);

    return function cleanupResizeListener() {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const domainStart = new Date("4/15/2020");
  const domainEnd = new Date(
    process.env.REACT_APP_BUBBLE_TIMELINE_GRID_DOMAIN_END ?? "4/15/2022"
  );

  const xScale: ScaleTime<number, number> = d3
    .scaleTime<number, number>()
    .domain([domainStart, domainEnd])
    .range([0, dimensions.width - margin.left]);

  if (zoomState) {
    xScale.domain(zoomState.rescaleX(xScale).domain());
  }

  const xAxisRefCallback = (ref: any) => {
    const xAxis: Selection<SVGGElement, any, any, any> = d3.select<
      SVGGElement,
      any
    >(ref as any);
    xAxis.call(d3.axisTop<Date>(xScale).tickFormat(d3.timeFormat("%b '%y")));
  };

  React.useEffect(() => {
    const zoom: any = d3
      .zoom()
      .scaleExtent([1, 1]) // This control how much you can unzoom (x0.5) and zoom (x2)
      .extent([
        [0, 0],
        [dimensions.width, dimensions.height],
      ])
      .translateExtent([
        [-dimensions.width / 2, 0],
        [dimensions.width * (3 / 2), dimensions.height],
      ]);

    zoom.on("zoom", function updateChart(event: any) {
      setZoomState(event.transform);
    });

    d3.select(svgRef.current).call(zoom);

    return () => {
      zoom.on("zoom", null);
    };
  }, []);

  const state = getAttacks(props, dimensions);

  return (
    <div ref={containerRef} className="timeline-container">
      <svg
        ref={svgRef}
        width={dimensions.width + margin.left + margin.right}
        height={dimensions.height + margin.top + margin.bottom}
      >
        <defs>
          <clipPath id="clipPath1">
            <rect
              width={dimensions.width}
              height={dimensions.height}
              x={0}
              y={0}
            ></rect>
          </clipPath>
        </defs>
        <g
          id="main-graph-container"
          transform={"translate(" + margin.left + "," + margin.top + ")"}
        >
          {state.sectorsY &&
            Object.entries(state.sectorsY).map(
              ([sectorName, yValue], index) => (
                <g key={`lines-${index}`}>
                  <line
                    x1="0"
                    y1={yValue}
                    x2={dimensions.width - margin.left + 30}
                    y2={yValue}
                    stroke={index % 2 ? "#838383" : "#cdcdcd"}
                  />
                  <text
                    className="x label"
                    textAnchor="end"
                    x="0"
                    y={yValue + 4}
                    transform={"translate(" + -8 + "," + 0 + ")"}
                  >
                    {sectorName}
                  </text>
                </g>
              )
            )}
          <g id="datapoints-container">
            {state.attacks &&
              state.attacks.map((d, index) => (
                <g key={`datapoint-${index}`}>
                  <circle
                    ref={d.ref}
                    key={`datapoint-${index}`}
                    cx={xScale(d.date)}
                    cy={d.y}
                    r={d.r}
                    style={{
                      fill: "#003E97",
                      opacity: "1",
                      clipPath: "url(#clipPath1)",
                    }}
                  />
                  <Tooltip triggerRef={d.ref}>
                    <foreignObject
                      className="bubbleTimeline-tooltip-container"
                      textAnchor="start"
                      width={1}
                      height={1}
                    >
                      <div className="bubbleTimeline-tooltip">
                        {getModalData(d)}
                      </div>
                    </foreignObject>
                  </Tooltip>
                </g>
              ))}
          </g>

          <g
            ref={xAxisRefCallback}
            transform={"translate(0," + (height - 22) + ")"}
          ></g>
          <text
            className="x label"
            textAnchor="middle"
            x={dimensions.width / 2}
            y={dimensions.height}
          >
            Year - Month
          </text>
        </g>
      </svg>
    </div>
  );
}

export default BubbleTimelineGrid;
