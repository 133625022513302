import React from "react";
import Impact from "../../lib/Impact";
import Fade from "../../lib/Fade";
import Bubble from "../../lib/Bubble";

export default function Chapter3() {
  return (
    <section className="py-16 home-section home-section-help">
      <Impact
        line1="Cyberattacks against healthcare affect lives."
        line2="They can be stopped."
        line3="They must be stopped."
        sameSize={true}
      />

      <Fade className="max-w-full">
        <hr className="white my-16" />
      </Fade>

      <Fade>
        <h2 className="my-16 text-5xl md:text-6xl text-white">
          How can you help?
        </h2>
      </Fade>

      <div
        className="my-8 md:my-16 grid md:grid-cols-3 gap-8 flex-col md:flex-row"
        style={{ maxWidth: "84rem" }}
      >
        <Fade>
          <p className="px-8 md:px-0">
            <strong>Understanding the true scale and societal impact</strong> of
            cyberattacks on healthcare can lead to change.
          </p>
        </Fade>
        <Fade>
          <p className="px-8 md:px-0">
            <strong>Time to act is now!</strong> But an availability of data and
            a disparity in reporting cyberattacks across the world complicates
            actions.
          </p>
        </Fade>
        <Fade>
          <p className="px-8 md:px-0">
            <strong>The CIT #HEALTH platform informs and influences</strong>{" "}
            policy and practice through tracing cyberattacks on healthcare. The
            CyberPeace Institute is continuing to collect data and make it
            transparent. Join the endeavour!
          </p>
        </Fade>
      </div>

      <Fade>
        <Bubble className="h-72 w-72 ml-auto mr-auto md:h-96 md:w-96 p-8 md:p-16 border-t border-b border-l border-r" />
      </Fade>
    </section>
  );
}
