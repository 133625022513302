import React from "react";
import "./PercentageBarChart.css";
import ReactTooltip from "react-tooltip";
import colorDictionary from "../../utils/colors/ColorDictionary";
import ChartTitle from "../../lib/diagrams/ChartTitle";
import Chart from "../../lib/diagrams/Chart";
import { YesNoUnknown } from "../types";

interface Percentage {
  first: string;
  firstText: string;
  second: string;
  secondText: string;
  third: string;
  thirdText: string;
}

const initialPercentages = {
  first: "0%",
  firstText: "Unknown",
  second: "0%",
  secondText: "Yes",
  third: "0%",
  thirdText: "No",
};

const delay = 100;

interface Props {
  percentages: YesNoUnknown;
  hideLegend?: boolean;
  hideDisclaimer?: boolean;
  title: string;
  img?: string;
}

export default function PercentageBarChart(props: Props) {
  const percentages: Percentage = {
    ...initialPercentages,
    ...getValues(props),
  };

  return (
    <Chart className="percentage-container">
      <ChartTitle title={props.title} />
      <div className={props.img ? "percentage-row-with-img" : ""}>
        {props.img && (
          <img
            alt=""
            className="w-full md:w-32 h-auto"
            src={"/images/" + props.img}
          />
        )}
        <div
          className="percentage-row"
          style={{ background: getRowBackground(percentages) }}
        >
          {isZero(percentages) && (
            <div
              className="percentage-block first"
              style={getStyle("100%", percentages.firstText)}
            >
              No results
            </div>
          )}
          {!isZero(percentages) && (
            <>
              <div
                data-for="percentage-first-tool-box"
                data-tip={percentages.firstText}
                className="percentage-block first"
                style={getStyle(percentages.first, percentages.firstText)}
              >
                {formatPercentageText(percentages.first)}
              </div>
              <ReactTooltip
                id="percentage-first-tool-box"
                delayHide={delay}
                effect="solid"
              />

              <div
                data-for="percentage-second-tool-box"
                data-tip={percentages.secondText}
                className="percentage-block"
                style={getStyle(percentages.second, percentages.secondText)}
              >
                {formatPercentageText(percentages.second)}
              </div>
              <ReactTooltip
                id="percentage-second-tool-box"
                delayHide={delay}
                effect="solid"
              />

              <div
                data-for="percentage-third-tool-box"
                data-tip={percentages.thirdText}
                className="percentage-block last"
                style={getStyle(percentages.third, percentages.thirdText)}
              >
                {formatPercentageText(percentages.third)}
              </div>
              <ReactTooltip
                id="percentage-third-tool-box"
                delayHide={delay}
                effect="solid"
              />
            </>
          )}
        </div>
      </div>
      {!props.hideLegend && (
        <>
          <div className="flex justify-center gap-4">
            <Legend item="first" percentages={percentages} />
            <Legend item="second" percentages={percentages} />
            <Legend item="third" percentages={percentages} />
          </div>
        </>
      )}

      <div className="disclaimer">
        {!props.hideDisclaimer && (
          <>
            *Non-applicable incidents (ie - non patient care services) are
            excluded from this graphic
          </>
        )}
      </div>
    </Chart>
  );
}

interface LegendProps {
  item: keyof Percentage;
  percentages: Percentage;
}
function Legend(props: LegendProps) {
  const percentage = props.percentages[props.item];
  const text = props.percentages[`${props.item}Text` as keyof Percentage];
  return (
    <div className="flex items-center gap-2">
      <div
        className="rounded-full w-4 h-4"
        style={{
          minWidth: "1rem",
          backgroundColor: getStyle(percentage, text).background,
        }}
      ></div>
      {text}
    </div>
  );
}

function getStyle(percentage: string, text: string) {
  return {
    width: percentage,
    background: colorDictionary.yesNoUnknown[text.toLowerCase()] as string,
  };
}

function getRowBackground(percentages: Percentage) {
  return parseInt(percentages.third) > 0
    ? (colorDictionary.yesNoUnknown[
        percentages.thirdText.toLowerCase()
      ] as string)
    : parseInt(percentages.second) > 0
    ? (colorDictionary.yesNoUnknown[
        percentages.secondText.toLowerCase()
      ] as string)
    : (colorDictionary.yesNoUnknown[
        percentages.firstText.toLowerCase()
      ] as string);
}

function formatPercentageText(text: string) {
  return text === "0%" ? "" : parseInt(text) < 5 ? "" : text;
}

function yesNoUnknownToArray(percentages: YesNoUnknown) {
  return [percentages.unknown, percentages.yes, percentages.no];
}

function isZero(percentages: Percentage) {
  return (
    parseInt(percentages.third) === 0 &&
    parseInt(percentages.second) === 0 &&
    parseInt(percentages.first) === 0
  );
}

function clearNaN(percentage: number) {
  return isNaN(percentage) ? 0 : percentage;
}

function getValues(props: Props) {
  if (props.percentages) {
    const percentageValues = yesNoUnknownToArray(props.percentages);
    let allRoundedPercentageCounter = 0;

    const allCounter = percentageValues.reduce((a, b) => a + b);

    let valuesArray = percentageValues.map((value, index) => {
      return {
        value: (value / allCounter) * 100,
        index: index,
      };
    });

    let roundedValuesArray = valuesArray.map((entry) => {
      let roundedValue = Math.round(entry.value);
      allRoundedPercentageCounter += roundedValue;
      return roundedValue;
    });

    if (allRoundedPercentageCounter > 100) {
      valuesArray.sort((a, b) => {
        let decimalA = a.value - Math.floor(a.value);
        let decimalB = b.value - Math.floor(b.value);

        return decimalA - decimalB;
      });
      for (let i = 0; allRoundedPercentageCounter > 100; i++) {
        if (roundedValuesArray[valuesArray[i].index] >= 1) {
          allRoundedPercentageCounter--;
          roundedValuesArray[valuesArray[i].index] -= 1;
        }
      }
    }

    let first = clearNaN(roundedValuesArray[0]) + "%";
    let second = clearNaN(roundedValuesArray[1]) + "%";
    let third = clearNaN(roundedValuesArray[2]) + "%";

    return {
      first,
      second,
      third,
    };
  }
}
