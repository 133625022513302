import React from "react";

import "./Home.css";
import DownArrow, { DownArrowSize } from "../../lib/DownArrow";
import LinkButton from "../../lib/LinkButton";
import Bubble from "../../lib/Bubble";
import Fade from "../../lib/Fade";
import Chapter1 from "./Chapter1";
import Chapter2 from "./Chapter2";
import Chapter3 from "./Chapter3";
import { Helmet } from "react-helmet";

export default function Home() {
  const [showActBubble, setShowActBubble] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>Cyber Incident Tracer #HEALTH</title>
      </Helmet>
      <article className="mb-32 text-base md:text-xl text-center">
        <div
          className={`position-fixed hidden xl:flex flex-col right-8 top-32 z-10 transition-opacity duration-500 ease-in-out ${
            showActBubble ? "opacity-100" : "opacity-0"
          }`}
        >
          <Bubble
            className="h-72 w-72 p-10 mt-0 mb-8 bg-white border-cpi text-base border-2 text-cpi"
            showArrow={true}
          />

          <LinkButton
            className="px-4 py-2 bg-cpi text-base text-white font-extrabold"
            to="/act"
          >
            Act Now
          </LinkButton>
        </div>

        <Chapter1 />

        <hr />

        <Chapter2 setShowActBubble={setShowActBubble} />

        <Chapter3 />

        <Fade>
          <DownArrow className="mb-20" size={DownArrowSize.Big} />
        </Fade>

        <Fade>
          <LinkButton
            className="w-full md:w-auto px-8 md:px-16 py-4 bg-cpi text-3xl md:text-5xl text-white font-extrabold"
            to="/act"
          >
            Act Now
          </LinkButton>
        </Fade>
      </article>
    </>
  );
}
