import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { overrideClassNames } from "./utils";

interface Props {
  className?: string;
  to: string;
}

const defaultClassNames =
  "rounded-full border-b border-t border-l border-r border-cpi bg-white text-cpi px-4 py-4 text-center hover:text-sec hover:bg-sec hover:border-sec hover:shadow-lg hover:no-underline transition-shadow";

export default function LinkButton(props: PropsWithChildren<Props>) {
  const { children, className = "", to } = props;
  const isNormalLink = to.startsWith("mailto:") || to.startsWith("http");

  if (isNormalLink) {
    return (
      <a className={overrideClassNames(defaultClassNames, className)} href={to}>
        {children}
      </a>
    );
  }
  return (
    <Link className={overrideClassNames(defaultClassNames, className)} to={to}>
      {children}
    </Link>
  );
}
