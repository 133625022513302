import React from "react";
import TimeBarChartWithDrillDownByFilter from "../d3/TimeBarChartWithDrillDownByFilter";
import ChartTitle, {
  TitleWithButtonsType,
} from "../../lib/diagrams/ChartTitle";
import { Attack } from "../types";
import Chart from "../../lib/diagrams/Chart";

interface Props {
  attacks: Attack[];
}

export default function IncidentsOverTime(props: Props) {
  const [show, setShow] = React.useState(TitleWithButtonsType.Left);

  return (
    <Chart>
      <ChartTitle
        title="Incidents over time (by month)"
        buttonLeft="By Sub-Sector"
        buttonRight="By Region"
        onClick={setShow}
      />

      {show === TitleWithButtonsType.Left && (
        <TimeBarChartWithDrillDownByFilter
          attacks={props.attacks}
          filterBy="SubSector"
          allowDrilldown={true}
        />
      )}

      {show === TitleWithButtonsType.Right && (
        <TimeBarChartWithDrillDownByFilter
          attacks={props.attacks}
          filterBy="Region"
          allowDrilldown={false}
        />
      )}
    </Chart>
  );
}
