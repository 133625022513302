const gradientOfColorsOnSubSectors: Record<string, string[]> = {
  pharmaceuticals: ["#1BD3B0", "#096375", "#68A98D"],
  "patient care services": [
    "#023E7D",
    "#005CA8",
    "#3864C8",
    "#598EC7",
    "#4777F5",
    "#68A3EA",
    "#94C6F7",
    "#BBE0FF",
  ],
  "medical manufacturing & development": ["#E09430", "#d2a160"],
  other: ["#606060", "#838282", "#c3c3c3", "#E8E8E8"],
};

const gradientOfColorsOnAttackCategories: Record<string, string[]> = {
  "disruptive attack": ["#2ececa", "#3cb7b3", "#58c4c2"],
  "data breach": ["#f5962a", "#eaa246", "#e5ac63"],
  unknown: ["#2ececa", "#58c4c2"],
};

const subSector: Record<string, string | Record<string, string[]>> = {
  pharmaceuticals: "#00A8A9",
  "patient care services": "#365FD9",
  "medical manufacturing & development": "#FCB800",
  other: "#E8E8E8",
  gradientOfColors: gradientOfColorsOnSubSectors,
};

const attackCategory: Record<string, string | Record<string, string[]>> = {
  "disruptive attack": "#2ECECA",
  "data breach": "#F5962A",
  unknown: "#E8E8E8",
  gradientOfColors: gradientOfColorsOnAttackCategories,
};

const attackType: Record<string, string | Record<string, string[]>> = {
  ransomware: "#2ececa",
  ddos: "#4777F5",
  "malware (except ransomware)": "#F5962A",
  malware: "#F5962A",
  unknown: "#E8E8E8",
};

const region: Record<string, string | Record<string, string[]>> = {
  africa: "#F78E48",
  americas: "#FFC26E",
  asia: "#7D6ED6",
  europe: "#003E97",
  oceania: "#2D78FF",
};

const yesNoUnknown: Record<string, string> = {
  yes: "#05C680",
  no: "#F5712A",
  unknown: "#919191",
};

const colorDictionary: Record<
  string,
  Record<string, string | Record<string, string[]>>
> = {
  SubSector: subSector,
  AttackCategory: attackCategory,
  AttackType: attackType,
  Region: region,
  yesNoUnknown,
};

export default colorDictionary;
