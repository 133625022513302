import React from "react";
import Moment from "moment";
import colorDictionary from "../../utils/colors/ColorDictionary";
import { getWidthWithMin, handleResizeWithMin } from "../../utils/window";
import * as d3 from "d3";
import "./NetworkGraphChart.css";
import { Attack } from "../types";
import { Tooltip } from "react-svg-tooltip";
import { returnSubSectors } from "../../lib/filters/utils";

function isApplicable(attack: Attack) {
  return (
    (attack.subType.toLowerCase() === "ransomware" ||
      attack.subType.toLowerCase() === "ransomware (unconfirmed)") &&
    attack.involvedThreatActor[0] &&
    attack.involvedThreatActor[0].type.toLowerCase() === "ransomware operator"
  );
}

function getInnerNodeModalData(point: OperatorAttacks) {
  return (
    <>
      <b className="text-center">{point.operator}</b>
      <br />
      Incidents: <b>{point.items.length}</b>
    </>
  );
}

function getOuterNodeModalData(point: TermByOperator) {
  return (
    <>
      <b className="text-center">{point.date}</b>
      <br />
      {point.name}
    </>
  );
}

interface TermsByOperator {
  [key: string]: TermByOperator[];
}

interface IState {
  attacks: OperatorAttacks[];
}

interface OperatorAttacks {
  ref: any;
  operator: string;
  items: TermByOperator[];
}

interface TermByOperator {
  ref: any;
  name: string;
  date: string;
  operator: string;
  category: string;
  nodeColor: string;
}

function getRansomwareOperators(attacks: Attack[]) {
  return Array.from(
    new Set(
      attacks.map(({ involvedThreatActor }) => involvedThreatActor[0].name)
    )
  );
}

function getGroupedAttacks(attacks: Attack[], operators: string[]) {
  return operators.reduce((acc: TermsByOperator, operator) => {
    const attacksByOperator = attacks.filter(
      (attack) => attack.involvedThreatActor[0].name === operator
    );

    acc[operator] = acc[operator] ?? [];

    acc[operator].push(
      ...attacksByOperator.map((attack) => {
        return {
          ref: React.createRef(),
          name: attack.eventName,
          date: Moment(attack.eventDateFrom).format("D-MMM-YYYY"),
          operator,
          category: attack.involvedOrganization[0].primarySubSector,
          nodeColor: colorDictionary.SubSector[
            attack.involvedOrganization[0].primarySubSector.toLowerCase()
          ] as string,
        };
      })
    );
    return acc;
  }, {});
}

function getSeries(attacks: Attack[]): OperatorAttacks[] {
  if (!attacks || !attacks.length) {
    return [];
  }

  const filteredAttacks = attacks.filter((attack) => isApplicable(attack));

  const operators = getRansomwareOperators(filteredAttacks);
  const attacksGroupedByOperator = getGroupedAttacks(
    filteredAttacks,
    operators
  );
  const sortedAttacks = Object.values(attacksGroupedByOperator).sort(
    (a, b) => b.length - a.length
  );
  const firstTenCategories = sortedAttacks.splice(0, 10);

  firstTenCategories.forEach((category) =>
    category.sort((a, b) => b.nodeColor.localeCompare(a.nodeColor))
  );

  // return initSeries(firstTenCategories);
  return Object.values(
    firstTenCategories.map((category) => {
      return {
        ref: React.createRef(),
        operator: category[0].operator,
        items: category,
      };
    })
  );
}

interface Props {
  attacks: Attack[];
}

function NetworkGraphChart(props: Props) {
  // set the dimensions and margins of the graph
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  let width = getWidthWithMin(margin, 900),
    height = 400 - margin.top - margin.bottom;

  const [state, setState] = React.useState<IState>({ attacks: [] });
  const [dimensions, setDimensions] = React.useState({ width, height });
  const [page] = React.useState(1);

  const perLine = 5;
  const distanceFromCenter = 55;
  const radius = 8;
  const operatorRadius = d3.scaleLinear().domain([0, 30]).range([6, 20]);
  const cellSize = width / perLine;
  const positionAttacks = d3.scaleLinear().range([0, 360]);
  const positionAttack = (i: number, length: number) => {
    positionAttacks.domain([0, length]);
    return positionAttacks(i);
  };
  const angleX = (i: number, length: number) => {
    return (
      distanceFromCenter *
      Math.cos((positionAttack(i, length) * Math.PI) / 180.0)
    );
  };
  const angleY = (i: number, length: number) => {
    return (
      distanceFromCenter *
      Math.sin((positionAttack(i, length) * Math.PI) / 180.0)
    );
  };

  const xLineScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, distanceFromCenter]);
  const yLineScale = d3.scaleLinear().domain([0, 100]).range([0, 360]);
  const line = d3
    .line()
    .x(function (d, i: number) {
      return xLineScale(i);
    })
    .y(function (d, i: number) {
      return (
        (distanceFromCenter / 14) * Math.sin((yLineScale(i) * Math.PI) / 180.0)
      );
    })
    .curve(d3.curveBasis);

  const lineData: [number, number][] = [];
  for (let i in Array.from(Array(101).keys())) {
    let j: number = +i;
    lineData.push([j, j + 1]);
  }

  const startAngle = (310 * Math.PI) / 180.0;
  const endAngle = startAngle + (360 * Math.PI) / 180.0;
  const arc = d3.arc().innerRadius(0).startAngle(startAngle).endAngle(endAngle);
  const computeArc = (pointRadius: number) => {
    let d: any = {};
    arc.outerRadius(pointRadius);
    return arc(d) || "";
  };

  const computeX = (i: number) => {
    const n = Math.floor(i / 2);
    return cellSize / 2 + n * (dimensions.width / perLine);
  };
  const computeY = (i: number) => {
    const n = i % 2;
    return dimensions.height / 4 + n * (dimensions.height / 2);
  };

  React.useEffect(() => {
    const handler = () =>
      handleResizeWithMin(dimensions, setDimensions, margin, 900);
    window.addEventListener("resize", handler);

    return function cleanupResizeListener() {
      window.removeEventListener("resize", handler);
    };
  }, [page]);

  React.useEffect(() => {
    refreshData();
  }, [props.attacks]);

  function refreshData() {
    const { attacks } = props;
    let data: OperatorAttacks[] = [];

    if (attacks) {
      data = getSeries(attacks);
      if (data.length > 0) {
        operatorRadius.domain([0, data[0].items.length]);
      }
    }

    setState({
      attacks: data,
    });
  }

  return (
    <div className="network-graph-container">
      <svg
        className="network-graph-svg"
        width={dimensions.width + margin.left + margin.right}
        height={dimensions.height + margin.top + margin.bottom}
      >
        <defs>
          <clipPath id="clipPathGraph">
            <rect
              width={dimensions.width}
              height={dimensions.height}
              x={0}
              y={0}
            />
          </clipPath>
        </defs>

        <g>
          {state.attacks &&
            state.attacks.length > 0 &&
            state.attacks.map((attack, outerIndex) => {
              return attack.items.map((d, index) => (
                <g key={`datapoint-${outerIndex}-${index}`}>
                  <path
                    stroke="#838383"
                    fill="transparent"
                    d={line(lineData) || ""}
                    transform={`translate(${computeX(outerIndex)}, ${computeY(
                      outerIndex
                    )})
                         rotate(${positionAttack(index, attack.items.length)})`}
                  />
                  <circle
                    ref={d.ref}
                    cx={
                      computeX(outerIndex) + angleX(index, attack.items.length)
                    }
                    cy={
                      computeY(outerIndex) + angleY(index, attack.items.length)
                    }
                    r={radius * 0.7}
                    style={{ fill: d.nodeColor }}
                  />
                  <Tooltip triggerRef={d.ref}>
                    <foreignObject
                      className="network-graph-tooltip-container"
                      textAnchor="start"
                      width={1}
                      height={1}
                    >
                      <div
                        className="map-tooltip"
                        style={{ borderColor: d.nodeColor }}
                      >
                        {getOuterNodeModalData(d)}
                      </div>
                    </foreignObject>
                  </Tooltip>

                  {index === state.attacks[outerIndex].items.length - 1 && (
                    <g
                      transform={`translate(${computeX(outerIndex)},${computeY(
                        outerIndex
                      )})`}
                    >
                      <path
                        id={`main-node-${outerIndex}-${index}`}
                        style={{ fill: "none" }}
                        d={computeArc(radius * 10)}
                      />
                      <text dx="0">
                        <textPath
                          xlinkHref={`#main-node-${outerIndex}-${index}`}
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                          }}
                        >
                          {d.operator}
                        </textPath>
                      </text>

                      <circle
                        ref={attack.ref}
                        key={`datapoint-center-${outerIndex}-${index}`}
                        r={operatorRadius(attack.items.length)}
                        style={{ fill: "#000" }}
                      />
                      <Tooltip triggerRef={attack.ref}>
                        <foreignObject
                          className="network-graph-tooltip-container"
                          textAnchor="start"
                          width={1}
                          height={1}
                        >
                          <div
                            className="map-tooltip"
                            style={{ borderColor: "#000" }}
                          >
                            {getInnerNodeModalData(attack)}
                          </div>
                        </foreignObject>
                      </Tooltip>
                    </g>
                  )}
                </g>
              ));
            })}
        </g>
      </svg>

      <div className="network-graph-categories">
        <div className="flex justify-center gap-4">
          {returnSubSectors().map((subSector, index) => (
            <div className="flex items-center gap-2" key={`category-${index}`}>
              <div
                className="rounded-full w-4 h-4"
                style={{
                  minWidth: "1rem",
                  backgroundColor: colorDictionary.SubSector[
                    subSector.toLowerCase()
                  ] as string,
                }}
              ></div>
              {subSector}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NetworkGraphChart;
